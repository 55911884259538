//import consoleDebug from './common/debugLogging';
import { post } from 'axios';

import {getAuthToken} from './auth/token';


class API {
    // contains all endpoints used to communicate with the API

    constructor(api_uri){
        this.api_uri = api_uri
    }

    registerExternal(data){
        let uri = this.api_uri + '/auth_external'

        let options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            method: 'POST'
        }

        //console.log('REGISTER WITH EXTERNAL PROVIDER')
        //console.log(data)
        return fetch(uri, options)
    }

    getData(){
        let uri = this.api_uri + '/user_data'
        let options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader()
            },
            method: 'GET'
        }
        return fetch(uri, options)
    }

    updateLessonProgress(data){
        let uri = this.api_uri + '/lesson_progress'
        let options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader()
            },
            body: JSON.stringify(data),
            method: 'POST'
        }
        return fetch(uri, options)
    }

    getQuestions(data){
        let uri = `${this.api_uri}/get_questions/${data.course}/${data.title}/${data.level}`
        let options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader()
            },
            method: 'GET'
        }
        return fetch(uri, options)
    }

    getImageUrl(imageName){
        let uri = this.api_uri + '/picture/' + imageName
        return uri
    }

    addCourse(data){
        let uri = `${this.api_uri}/courses`
        let options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader()
            },
            body: JSON.stringify(data),
            method: 'POST'
        }
        return fetch(uri, options)
    }

    uploadAvatar(file){
        const formData = new FormData();
        formData.append('file', file)
        const config = {
            headers: {
                'Accept': 'application/json',
                //'Content-Type': 'application/json',
                'content-type': 'multipart/form-data',
                'Authorization': getAuthHeader()
            }
        }
        let uri = `${this.api_uri}/avatar`
        return post(uri, formData,  config)
    }

    updateUserSettings(userSettings){
        //upserts user settings - updates only the changed values
        let uri = `${this.api_uri}/user/settings`
        let options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader()
            },
            body: JSON.stringify(userSettings),
            method: 'POST'
        }
        return fetch(uri, options)
    }

    loadUserProfile(userSlug){
        let uri = `${this.api_uri}/users/${userSlug}/profile`
        let options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            },
            method: 'GET'
        }

        return fetch(uri, options)
    }

    inviteFriend(email, message){
        let uri = `${this.api_uri}/invite`
        let options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            },
            body: JSON.stringify({
                email: email,
                message: message
            }),
            method: 'POST'
        }

        return fetch(uri, options)

    }
}


function getAuthHeader(testId){
    let authHeader = 'Bearer ' + getAuthToken();
    return authHeader
}


export let api = new API(process.env.REACT_APP_API_URI);
//console.log(process.env.REACT_APP_API_URI)
//console.log(process.env)
