import React, { Component } from 'react';
import { connect } from 'react-redux';

import Question from './Question';
import QuestionControls from './QuestionControls';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';



let dummyLessonContent = `We can do simple mathematical operations using Python interactive console

An example code:

~~~python
2 + 2
~~~

will display

~~~commandline
4
~~~

in your console.

You can also use ~print~ function to display the value:

~~~python
print(5 + 5)
~~~

which prints out:

~~~commandline
10
~~~

The same goes for other common mathematical operators

~~~commandline
>>> print(10 - 2)
8
>>> print(5 * 3)
15
>>> print(20/4)
5
~~~

`



class LessonIntroduction extends Component {

    render() {
        const { t } = this.props;
        console.log(this.props);

        return <div className="lessonIntroductionContainer">
            <div className="lessonIntroductionContentContainer">
                <div className="lessonIntroductionTitle">{t(this.props.lessonTitle) || 'Numbers'}</div>
                <div className="lessonIntroductionContent">
                    <ReactMarkdown>
                        {t(this.props.lessonIntroductionContent) || dummyLessonContent}
                    </ReactMarkdown>
                </div>
            </div>

            <center>
            <div className="question-controls-lesson-introduction not-selectable">
                <button className="btn btn-primary btn-lg btn-highlight"
                    onClick={this.props.onClick}>{t('CONTINUE')}</button>
            </div>
            </center>
        </div>
    }

}


function select (state) {
  return {
    data: state
  }
}

export default withRouter(
    connect(select)(
        withTranslation()(
            LessonIntroduction
        )
    )
);