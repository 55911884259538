import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';



const Sk = require('skulpt');



function evaluateCode(code){
    //execute code in browser

    var output = ''

    function outf(text) {
        /*var mypre = document.getElementById("output");
        mypre.innerHTML = mypre.innerHTML + text;*/
        //alert(text);
        output += text
    }
    function builtinRead(x) {
        if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined)
                throw new Error("File not found: '" + x + "'");
        return Sk.builtinFiles["files"][x];
    }

   Sk.configure({output:outf, read:builtinRead});
   try {
      eval(Sk.importMainWithBody("<stdin>",false,code));
   }
   catch(e) {
       //TODO: send sentry error
       //alert(e.toString())
   }

    return {
        stdout: output
    }
}


class QuestionControls extends Component {

    constructor(props){
        super(props)

        this.state = {
            'view': 'controls' // controls/correct/wrong
        }

        this.isAnswerCorrect = this.isAnswerCorrect.bind(this)
    }

    isAnswerCorrect(){
        /*console.log('isAnswerCorrect')
        console.log(this.props.data.chosenAnswers)
        console.log(this.props.question.correctAnswers)
        console.log(this.props.data.chosenAnswers === this.props.question.correctAnswers)*/

        if(this.props.data.question.type === 'order' || this.props.data.question.type === 'single_choice')
            return JSON.stringify(this.props.data.chosenAnswers) === JSON.stringify(this.props.question.correctAnswers)
        if(this.props.data.question.type === 'multiple_choice')
            return JSON.stringify(this.props.data.chosenAnswers.sort()) === JSON.stringify(this.props.question.correctAnswers.sort())

        //TODO: pairs type
        //TODO: code complete type
        // compare with reference code
        //TODO: write code type
        if(this.props.data.question.type === 'write_code'){
            //TODO: evaluate code, compare outputs/run mini tests
            let result = evaluateCode(this.props.data.chosenAnswers[0])
            //console.log(result)
            //console.log(this.props.question.stdout)
            return result.stdout.trim() === this.props.question.stdout.trim() // TODO: shold we trim? Should we allow approximate answers?
            //return this.props.data.chosenAnswers[0] == this.props.question.correctAnswers[0]
        }
        //TODO: correct mistake type
        // execute code, compare outputs
        return JSON.stringify(this.props.data.chosenAnswers) === JSON.stringify(this.props.question.correctAnswers)
    }


    render(){
        const { t } = this.props;

        let checkmarkUrl = "/images/checkmark-icon.jpg"
        let xIconUrl = '/images/x-icon.png'

        let getRandomItem = (list) => list[Math.floor((Math.random()*list.length))];

        const WRONG_ANSWER_TEXTS = [
            'This does not seem right...',
            "Oops. It's not correct..."
        ]
        let wrongAnswerText = getRandomItem(WRONG_ANSWER_TEXTS);

        const CORRECT_ANSWER_PRAISES = [
            "CORRECT!",
            "Awesome!",
            "Correct! Keep it up!",
            "Great!",
            "Perfect!",
            "Good! Keep going!",
        ]
        let correctAnswerPraise = getRandomItem(CORRECT_ANSWER_PRAISES);

        let controlsCorrect = (
            <div className="question-controls correct-answer not-selectable slide-in-bottom">
                <div className="row">
                    <div className="col-lg-12">
                        <center>
                            <div className="row question-controls-row">
                                <div className="col" style={{display: 'flex', 'alignItems': 'center'}}>
                                    <div className="question-feedback-icon-wrapper">
                                        <img src={checkmarkUrl}
                                            className="question-feedback-icon"
                                            alt={"OK"}/>
                                    </div>
                                    <b className="correct-answer-praise-text">{t(correctAnswerPraise)}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                <div className="col" style={{display: 'flex', 'alignItems': 'center'}}>
                                    <button className="btn btn-primary btn-lg btn-highlight"
                                        onClick={()=>{
                                            this.setState({view: 'controls'}, () =>{
                                                //console.log(this.props)
                                                this.props.dispatch({
                                                    type: 'SET_GENERIC',
                                                    chosenAnswers: []
                                                })
                                                this.props.loadNextQuestion()
                                            })
                                        }}>{t('CONTINUE')}</button>
                                </div>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        )
        //col-2 col-6 col-4

        let controlsWrong = (
            <div className="question-controls incorrect-answer">
                <div className="row">
                    <div className="col-lg-12">
                        <center>
                            <div className="row question-controls-row">
                                <div className="col" style={{display: 'flex', 'alignItems': 'center'}}>
                                    <div className="question-feedback-icon-wrapper">
                                        <img src={xIconUrl}
                                            className="question-feedback-icon"
                                            alt={"X"}/>
                                    </div>
                                    {false&&<b style={{color: 'white'}}>{t(wrongAnswerText)}</b>}
                                    <button className="btn btn-primary btn-lg btn-normal" onClick={()=> this.props.dispatch({
                                        type: 'SET_GENERIC',
                                        chosenAnswers: this.props.question.correctAnswers
                                    })}>
                                        {t('Correct answer?')}
                                    </button>
                                </div>
                                <div className="col" style={{display: 'flex', 'alignItems': 'center'}}>
                                    <button className="btn btn-primary btn-lg btn-highlight-red"
                                        onClick={()=>{
                                            this.setState({view: 'controls'}, () => {
                                                this.props.dispatch({
                                                    type: 'SET_GENERIC',
                                                    chosenAnswers: []
                                                })
                                                //console.log(this.props)
                                                this.props.loadNextQuestion()
                                            })
                                        }}>{t('CONTINUE')}</button>
                                </div>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        )

        let controls = (
            <div className="question-controls not-selectable">
                <div className="row">
                    <div className="col-lg-12">
                        <center className="control-buttons">
                            <button
                                className="btn btn-secondary btn-lg btn-normal"
                                onClick={
                                    ()=>{
                                        let _isAnswerCorrect = false;
                                        let questionLog = {
                                            'question_id': this.props.data.question.id,
                                            'current_answer': this.props.data.chosenAnswers,
                                            'correct_answers': this.props.data.question.correctAnswers,
                                            'is_correct': _isAnswerCorrect
                                        }

                                        let wholeQuestionLog = this.props.data.questionLog || []
                                        wholeQuestionLog.push(questionLog)

                                        this.props.dispatch({
                                            'type': 'SET_GENERIC',
                                            questionLog: wholeQuestionLog
                                        })

                                        this.setState({view: 'wrong'})}
                                    }
                                >{t('SKIP')}</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="btn btn-primary btn-lg btn-highlight btn-answer"
                                disabled={this.props.data.chosenAnswers.length === 0}
                                onClick={
                                    ()=>{
                                        let _isAnswerCorrect = this.isAnswerCorrect()
                                        let questionLog = {
                                            'question_id': this.props.data.question.id,
                                            'current_answer': this.props.data.chosenAnswers,
                                            'correct_answers': this.props.data.question.correctAnswers,
                                            'is_correct': _isAnswerCorrect
                                        }

                                        let wholeQuestionLog = this.props.data.questionLog || []
                                        wholeQuestionLog.push(questionLog)

                                        this.props.dispatch({
                                            'type': 'SET_GENERIC',
                                            questionLog: wholeQuestionLog
                                        })


                                        if(_isAnswerCorrect){
                                            this.setState({view: 'correct'})
                                        } else {
                                            this.setState({view: 'wrong'})
                                        }
                                    }

                                }>{t('CHECK')}</button>
                        </center>
                        <br/>
                    </div>
                </div>
            </div>
        )

        if(this.state.view === 'correct'){
            return controlsCorrect
        }
        if(this.state.view === 'wrong')
            return controlsWrong

        return controls
    }

}


function select (state) {
  return {
    data: state
  }
}

export default connect(select)(withTranslation()(QuestionControls));