import React, { Component } from 'react';
import {connect} from 'react-redux';

import ReadonlyCodeEditor from './ReadonlyCodeEditor';
import RenderCodeEditor from './RenderCodeEditor';
import CodeEditor from './CodeEditor';
import '../assessment/quiz.css';
import Answers from './Answers';
import { withTranslation } from 'react-i18next';

import ReactMarkdown from 'react-markdown';


class Question extends Component {

    render(){
        const { t } = this.props;

        return <div>
            <div className="question-description">
                <div className="row">
                    <div className="col-lg-12">
                        {
                            this.props.question.paragraphs.map((paragraph, key) => {
                                if (paragraph.type === 'code'){
                                    if(['html_css', 'html' ,'css'].includes(paragraph.language)){
                                        return <RenderCodeEditor
                                                language={paragraph.language}
                                                paragraph={paragraph}
                                                value={paragraph.content}
                                                markers={paragraph.markers}
                                                key={key}/>
                                    }

                                    return <ReadonlyCodeEditor
                                                language={paragraph.language? paragraph.language: "python"}
                                                value={paragraph.content}
                                                markers={paragraph.markers}
                                                key={key}/>
                                }
                                if (paragraph.type === 'code_input'){
                                    return <CodeEditor
                                                language={paragraph.language? paragraph.language: "python"}
                                                raw={true}
                                                value={this.props.data.chosenAnswers[0]? this.props.data.chosenAnswers[0]: paragraph.content }
                                                onValueChange={(value)=>this.props.dispatch({'type':'SET_GENERIC', chosenAnswers: [value]})}
                                                key={key}
                                           />
                                }
                                if (paragraph.type === 'double_code'){
                                    return <div className="row">
                                        <div className="col-lg-6">
                                            {paragraph.content[0].fname}
                                            <ReadonlyCodeEditor
                                                    language={paragraph.language? paragraph.language: "python"}
                                                    raw={true}
                                                    value={ paragraph.content[0].code }
                                               />
                                        </div>
                                        <div className="col-lg-6">
                                            {paragraph.content[1].fname}
                                            <ReadonlyCodeEditor
                                                    language={paragraph.language? paragraph.language: "python"}
                                                    raw={true}
                                                    value={ paragraph.content[1].code }
                                               />
                                        </div>

                                    </div>
                                }
                                return <div className="question-question" key={key}>
                                           <ReactMarkdown>
                                                {t(paragraph.content, paragraph.vars)}
                                           </ReactMarkdown>
                                    </div>;
                                }
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="question-answers">
                <div className="row">
                    <div className="col-lg-12">
                        <center>
                            <Answers question={this.props.question}/>
                        </center>

                    </div>
                </div>
            </div>
        </div>
    }
}


function select (state) {
  return {
    data: state
  }
}

export default connect(select)(
    withTranslation()(
        Question
    )
);
