import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


class SummaryField extends Component {

    render(){
        const { t } = this.props;

        let icons = {
            'exp': '/images/icons/lamp-outline-icon-isolated-on-white-background-idea-icon-concept-eps10-free-vector.jpg',
            'time': '/images/icons/alarm-clock-outline_318-33901.gif',
            'accuracy': '/images/icons/target-icon.webp',
            undefined: '',
        }
        let expComments = {
            15: 'Awesome!',
            14: 'Great!',
            13: 'Nice!',
            12: 'Cool!',
            11: 'Good',
            10: 'Okay',
        }
        let timeComments = {
            0: 'Blazing fast!', //0-90 -> Blazing fast
            90: 'Quick!', // 90 - 120 -> quick
            120: 'Fast!',
            150: 'Good!',
            600: 'Okay',
            3600000: 'Oh...'
        }
        let accuracyComments = {
            100: 'Perfect',
            99: 'Awesome!',
            80: 'Very good!',
            70: 'Cool!',
            60: 'Okay',
            50: 'Oh...'
        }
        let classNames = {
            'exp': 'expSummary',
            'time': 'timeSummary',
            'accuracy': 'accuracySummary',
            undefined: ''
        }
        let classNamesHeader = {
            'exp': 'expSummaryHeader',
            'time': 'timeSummaryHeader',
            'accuracy': 'accuracySummaryHeader',
            undefined: ''
        }
        let classNamesValueColumn = {
            'exp': 'expSummaryValueColumn',
            'time': 'timeSummaryValueColumn',
            'accuracy': 'accuracySummaryValueColumn',
            'undefined': '',
        }
        let metricNames = {
            'exp': 'Experience',
            'time': 'Time spent',
            'accuracy': 'Accuracy',
            undefined: 'Metric',
        }

        let metric = metricNames[this.props.type]
        let iconUrl = icons[this.props.type]
        var comment = ''
        if(this.props.type === 'exp'){
            comment = expComments[this.props.value]
        } else if (this.props.type === 'time'){
            let times = Object.keys(timeComments); // already sorted ascending

            for (const time of times){
                if(this.props.value >= time){
                    comment = timeComments[time];
                }
            }
        } else if (this.props.type === 'accuracy'){
            let accuracies = Object.keys(accuracyComments).map(x=>parseInt(x, 10)).sort((a, b) => b - a); // sorted descending

            for (const acc of accuracies){
                if(this.props.value <= acc){
                    comment = accuracyComments[acc];
                }
            }
        } else {
            comment = 'Yeah!'
        }

        let renderedValue = getRenderedValue(this.props.value, this.props.type);

        return <div className={classNames[this.props.type]}>
            <div className="row">
                <div className="col">
                    <div className="lessonCompletedMetric">
                        <img src={iconUrl} style={{"height": "20px", "width": "20px"}}/>&nbsp;{t(metric)}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className={`col ${classNamesValueColumn[this.props.type]}`}>
                    <b className="lessonCompletedValue">{renderedValue}</b>
                </div>
            </div>
            <div className="row">
                <div className="col">
                     <div className="lessonCompletedMetric">
                        {t(comment)}
                     </div>
                </div>
            </div>
        </div>
    }
}


function getRenderedValue(value, type){
    if(type === 'accuracy'){
        return value.toString() + '%'
    } else if (type === 'time'){
        // value == seconds
        if(value < 3600){
            return new Date(value * 1000).toISOString().substring(14, 19)
        } else {
            return new Date(value * 1000).toISOString().substring(11, 16)
        }
    }
    return value
}


function select (state) {
  return {
    data: state
  }
}


export default connect(select)(
    withTranslation()(
        SummaryField
    )
);