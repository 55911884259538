import React, { Component } from 'react';
import {connect} from 'react-redux';
import ChartistGraph from 'react-chartist';

import ProgressBar from './ProgressBar';
import { withTranslation } from 'react-i18next';


class Score extends Component {
    render() {
        const { t } = this.props;

        if(this.props.data.isLoading){
            return <div/>
        }

        const today = new Date();
        const todayWeekDay = today.getDay()
        const expLabelsNum = [0,1,2,3,4,5,6].map((i) => (todayWeekDay + i) %7)
        const weekDayLabels = [
            t('Mon'),
            t('Tue'),
            t('Wed'),
            t('Thu'),
            t('Fri'),
            t('Sat'),
            t('Sun')
        ]
        const expLabels = expLabelsNum.map((label) => weekDayLabels[label])

        let expGainedLastDays = this.props.data.userProfile.expGained.slice(0,6)
        let expGained = expGainedLastDays
        expGained.push(this.props.data.userProfile.expGainedToday)

        let percentCompletionToColor = {
            0: 'rgb(0, 0, 0)',
            10: 'rgb(255, 166, 11)',
            50: 'rgb(221, 221, 7)',
            75: 'rgb(169, 224, 65)',
            99: 'rgb(0, 229, 111)'
        }
        let percentCompletion = Math.floor(100 * this.props.data.userProfile.expGainedToday/this.props.data.userProfile.dailyGoal)

        let completionColor = percentCompletionToColor[0];
        for (const pctCompletionKey in percentCompletionToColor ) {
            if(percentCompletion > pctCompletionKey){
                completionColor = percentCompletionToColor[pctCompletionKey]
            }
        }
        let expGainedStyle = {
            'color': completionColor,
            'fontWeight': 600
        }

        return <div className="widgetProfile">
            <center style={{'paddingBottom': '20px'}}><h2 className="widgetTitle">{t('Daily goal')}</h2></center>
            <div className="row">
                <div className="col-lg-5">
                    <div style={{'minHeight': '80px', 'textAlign': 'center', 'paddingBottom': '10px'}}>
                        {this.props.data.userProfile.expGainedToday < this.props.data.userProfile.dailyGoal?
                        <ProgressBar progress={percentCompletion} fontSize={'32px'}/>
                        :
                        <img src="/images/streak-icon.png" alt={'Daily goal reached'} className="daily-goal-reached-img"/>
                        }
                    </div>
                </div>
                <div className="col-lg-7">
                    {t('Your Score Today')}: <b style={expGainedStyle}>{this.props.data.userProfile.expGainedToday}</b> {t('exp')}<br/>
                    {t('Goal')}: <b style={expGainedStyle}>{this.props.data.userProfile.expGainedToday}</b> / {this.props.data.userProfile.dailyGoal} {t('exp')}<br/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {t('Last week')}:<br/>
                    <Bar labels={expLabels} values={expGained}/>
                </div>
            </div>
        </div>
    }
}


class Bar extends Component {

  render() {

    var data = {
      labels: this.props.labels || ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      series: [
        this.props.values? this.props.values: [0, 0, 0, 0, 0, 0, 0]
      ]
    };

    var options = {
      high: Math.max(...data.series[0]) > 0? Math.max(...data.series[0]): 10,
      low: 0,
      axisX: {
        labelInterpolationFnc: function(value, index) {
          return index % 1 === 0 ? value : null;
        }
      }
    };

    var type = 'Line'

    return (
      <div>
        <ChartistGraph data={data} options={options} type={type} />
      </div>
    )
  }
}


function select (state) {
  return {
    data: state
  }
}


export default connect(select)(
    withTranslation()(
        Score
    )
);