/*
 * Actions describe changes of state in your application
 */

// We import constants to name our actions' type
import {
  SENDING_REQUEST,
  UPDATE_AUTHORIZATION,
  LOGOUT,
  REQUEST_ERROR,
  REMOVE_ERROR,

  //START_TEST,
  //SYNCHRONIZE_TEST_STATE_REQUEST,

  REGISTER_OR_LOGIN,
} from './constants'

/**
 * Sets the authentication state of the application
 * @param  {boolean} newAuthState True means a user is logged in, false means no user is logged in
 */
export function setAuthState (newAuthState) {
  return {type: UPDATE_AUTHORIZATION, newAuthState}
}

/**
 * Sets the `isSending` state, which displays a loading indicator during requests
 * @param  {boolean} sending True means we're sending a request, false means we're not
 */
export function sendingRequest (sending) {
  return {type: SENDING_REQUEST, sending}
}


/**
 * Tells the app we want to log out a user
 */
export function logout () {
  return {type: LOGOUT}
}


/**
 * Sets the `error` state to the error received
 * @param  {object} error The error we got when trying to make the request
 */
export function requestError (error) {
  return {type: REQUEST_ERROR, error}
}

/**
 * Sets the `error` state as empty
 */
export function clearError () {
  return {type: REMOVE_ERROR}
}


export function registerOrLogin(data){
    return {
        type: REGISTER_OR_LOGIN,
        data: data
    }
}