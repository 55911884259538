import {getCookie, setCookie, removeCookie} from '../common/simpleCookieUtils';


// Side effects Services
export function getAuthToken(){
  return getCookie('codilingo_token')
}


export function setAuthToken(token){
  setCookie('codilingo_token', token, 60*60*36) //60*60*8 60*60*36
}


export function removeAuthToken(){
  removeCookie('codilingo_token')
}


/*

export function getAuthToken() {
  return JSON.parse(localStorage.getItem('authToken'))
}

export function setAuthToken(token) {
  localStorage.setItem('authToken', JSON.stringify(token))
}

export function removeAuthToken() {
  localStorage.removeItem('authToken')
}

*/