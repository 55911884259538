import React, { Component } from 'react';
import {connect} from 'react-redux'

import LessonTree from './course/LessonTree';
import Score from './widgets/Score';
import Friends from './widgets/Friends';
import Leaderboard from './widgets/Leaderboard';
import SocialFollow from './widgets/SocialFollow';
import Footer from './Footer';
import RecommendedGroups from './widgets/RecommendedGroups';
import Alert from './common/Alert';
import { withTranslation } from 'react-i18next';


class Dashboard extends Component {

    componentDidMount(props){
        this.props.dispatch({type: 'LOAD_DATA'})
    }

    render() {
        const { t } = this.props;


        return <div>
            <div className="dashboard-wrapper">
                {false? <Alert
                    content={<div>{t('Using Codilingo on a mobile? For better user experience, switch to our')} <a href="https://google.com">{t('mobile app')}</a></div>}
                    />: ''}
                <div className="row">
                    <div className="col-lg-7">
                        <LessonTree/>
                        <Footer/>
                    </div>
                    <div className="col-lg-5">
                        <div className="widgets" style={{'marginTop': '30px'}}>
                            <Score/>
                            {false? <Leaderboard/>: ''}
                            {false? <Friends/>: ''}
                            <RecommendedGroups/>
                            <SocialFollow/>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    }
}


function select (state) {
  return {
    data: state
  }
}

export default connect(select)(
    withTranslation()(
        Dashboard
    )
);