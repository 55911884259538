import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import { withTranslation } from 'react-i18next';


class RegisterForm extends Component {

    render(){
        const { t } = this.props;

        let registerButton = <Link to='/register'>
            <button className="btn btn-primary btn-highlight btn-login">{t('Register')}</button>
        </Link>

        return <div className="col-lg-4 colLoginButtons">
            <div style={{color: 'white', 'width': '300px', 'background-color': /*'#132d4e'*/ '#133153', 'border-radius': '10px'}}>
                <div className="row inputRow">
                    <div className="col-lg-12">
                        <h1>Fill the form</h1>
                    </div>
                </div>
                <div className="row inputRow">
                    <div className="col-lg-12">
                        <label htmlFor="username">{t('Username')}</label> <br/>
                        <input type="text" className="input" name="username" style={{width: '300px'}}></input>
                    </div>
                </div>
                <div className="row inputRow">
                    <div className="col-lg-12">
                        <label htmlFor="email">Email</label> <br/>
                        <input type="email" className="input" name="email" style={{width: '300px'}}></input>
                    </div>
                </div><br/>

                <div className="row">
                    <div className="col-lg-12">
                        {registerButton}
                    </div>
                </div>
            </div>
        </div>
    }
}



function select (state) {
  return {
    data: state
  }
}

export default connect(select)(
    withTranslation()(
        RegisterForm
    )
);