import React, { Component } from 'react';
//import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import { withTranslation } from 'react-i18next';



class Footer extends Component {
    render() {
        const { t } = this.props;

        return <footer
            style={{width: '1100px', margin: '0 auto', 'paddingBottom': '25px'}}>
                    <Link to="/about">{t('About')}</Link> &nbsp; &nbsp;
                    <Link to="/terms-and-conditions">{t('Terms and Conditions')}</Link> &nbsp; &nbsp;
                    <Link to="/privacy-policy">{t('Privacy Policy')}</Link>

        </footer>
    }
}


export default withTranslation()(
    Footer
);