import consoleDebug from './../common/debugLogging';
import Cookies from 'universal-cookie';


export function setCookie(key, value, time){
    const cookies = new Cookies();
    consoleDebug(key); consoleDebug(value);
    //cookies.set(key, value, { path: '/', maxAge: 3600 * 24 * 7 }); //3600 = 1h
    cookies.set(key, value, { path: '/', maxAge: time});
    consoleDebug('setting cookie');
    consoleDebug(cookies.get(key));
    consoleDebug('cookie set');
}


export function hasCookie(key){
    let cookies = new Cookies();
    return Boolean(cookies.get(key));
}


export function getCookie(key){
    let cookies = new Cookies();
    return cookies.get(key);
}


export function removeCookie(key){
    let cookies = new Cookies();
    return cookies.remove(key);
}