import React, { Component } from 'react';
import {connect} from 'react-redux';

/*TODO: handle different question types differently*/
class Answers extends Component {

    constructor(props){
        super(props)
        this.setAnswer = this.setAnswer.bind(this)
    }

    setAnswer(answer){
        let answers = []

        if(this.props.data.question.type === 'single_choice'){
            answers = [answer]
        } else {
            answers = [...this.props.data.chosenAnswers, answer]
        }
        this.props.dispatch({
            type: 'SET_GENERIC',
            chosenAnswers: answers
        })
    }

    removeAnswer(answer){
        let answers = this.props.data.chosenAnswers.filter(a => a !== answer)
        this.props.dispatch({
            type: 'SET_GENERIC',
            chosenAnswers: answers
        })
    }

    render(){
        let answerClass = "btn btn-secondary btn-lg btn-normal btn-lesson"
        let answerClassChosen = "btn btn-secondary btn-lg btn-highlight btn-lesson"

        return <div>
            {
                this.props.question.answers && this.props.question.answers.map((answer, id) =>
                    <button
                        key={id}
                        className={this.props.data.chosenAnswers.includes(answer)? answerClassChosen: answerClass}
                        onClick={this.props.data.chosenAnswers.includes(answer)?
                            () => this.removeAnswer(answer)
                            : () => this.setAnswer(answer)}
                        style={{'boxShadow': '0 1px 3px 0px rgba(0, 0, 0, .2)'}}>
                        {answer}
                    </button>
            )}
        </div>
    }
}



function select (state) {
  return {
    data: state
  }
}

export default connect(select)(Answers);
