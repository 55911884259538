import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism"; // You can choose another theme

import Question from './Question';
import QuestionControls from './QuestionControls';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';



let dummyLessonContent = `We can do simple mathematical operations using Python interactive console

An example code:

~~~python
2 + 2
~~~

will display

~~~commandline
4
~~~

in your console.

You can also use ~print~ function to display the value:

~~~python
print(5 + 5)
~~~

which prints out:

~~~commandline
10
~~~

The same goes for other common mathematical operators

~~~commandline
>>> print(10 - 2)
8
>>> print(5 * 3)
15
>>> print(20/4)
5
~~~

`

const MarkdownRenderer = ({ content }) => {
  return (
    <ReactMarkdown
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              style={dracula}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
};


class QuestionHint extends Component {

    render() {
        const { t } = this.props;
        console.log(this.props);

        return <div className="lessonIntroductionContainer">
            <div className="lessonIntroductionContentContainer">
                <div className="lessonIntroductionTitle">{t(this.props.lessonTitle) || 'Numbers'}</div>
                <div className="lessonIntroductionContent">
                    <MarkdownRenderer content={t(this.props.lessonIntroductionContent) || dummyLessonContent} />
                </div>
            </div>

            <center>
            <div className="question-controls-lesson-introduction not-selectable">
                <button className="btn btn-primary btn-lg btn-highlight"
                    onClick={this.props.onClick}>{t('CONTINUE')}</button>
            </div>
            </center>
        </div>
    }

}


function select (state) {
  return {
    data: state
  }
}

export default withRouter(
    connect(select)(
        withTranslation()(
            QuestionHint
        )
    )
);