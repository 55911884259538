import React, { Component } from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
//import {getLessonQuestions} from '../../common/questions';
import { withTranslation } from 'react-i18next';
import saveGAEvent from '../../common/events';


class LessonWidget extends Component {

    constructor(props){
        super(props)
        this.loadQuestions = this.loadQuestions.bind(this)
    }

    loadQuestions(language, title, level){
        /*const questions = getLessonQuestions('python', title)
        console.log(title)
        console.log(questions)
        this.props.dispatch({
            type: 'SET_GENERIC',
            lesson: {
                name: title,
                level: level,
                questions: questions
            },
            question: questions[0]
        })*/

        this.props.dispatch({
            type: 'LOAD_LESSON',
            'course': language,
            'title': title,
            'level': level,
        })
    }

    render() {
        const { t } = this.props;

        let lessonState = this.props.completed?
            'completed': this.props.open ?
                'started': 'notStarted'

        let imgClass = {
            completed: 'lessonImgCompleted',
            started: 'lessonImgStarted',
            notStarted: 'lessonImgNotStarted'
        }[lessonState]

        let inactiveLesson = <div className="not-started-lesson-div">
            <div className="lesson-img-wrapper">
                <img src={this.props.icon} className={"lesson-img " + imgClass} alt={this.props.title}/>
            </div>
            <div style={{'color': 'gray', 'fontWeight': 'bold'}}>{t(this.props.title)}</div><br/>
        </div>

        if(this.props.open === false)
            return inactiveLesson

        let normalizedLessonTitle = this.props.title.replace(/ /g, '_').toLowerCase()
        let lessonLink = `/lesson/${this.props.language}/${normalizedLessonTitle}/${this.props.level}`

        return <div className="lesson-div">
            <Link to={lessonLink}
                onClick={
                    ()=>{
                        saveGAEvent({
                            category: 'usage',
                            action: 'lesson started',
                            course: this.props.language,
                            lesson: normalizedLessonTitle,
                            level: this.props.level,
                            user_id: this.props.userProfile.userId
                        })
                        this.loadQuestions(this.props.language, normalizedLessonTitle, this.props.level - 1)
                    }
                }
                >
                <div className="lesson-img-wrapper">
                    <img src={this.props.icon} className={"lesson-img " + imgClass} alt={this.props.title}/>
                </div>
                <div>{t(this.props.title)}</div>
                <center>
                    <ProgressBar progress={this.props.progress}/>
                </center>
                <Level level={this.props.level} max={this.props.maxLevels || 3}/>
            </Link>
        </div>
    }
}


// TODO: implement new reduced look?
class LessonWidgetNew extends Component {

    constructor(props){
        super(props)
        this.loadQuestions = this.loadQuestions.bind(this)
    }

    loadQuestions(language, title, level){
        /*const questions = getLessonQuestions('python', title)
        console.log(title)
        console.log(questions)
        this.props.dispatch({
            type: 'SET_GENERIC',
            lesson: {
                name: title,
                level: level,
                questions: questions
            },
            question: questions[0]
        })*/

        this.props.dispatch({
            type: 'LOAD_LESSON',
            'course': language,
            'title': title,
            'level': level,
        })
    }

    render() {
        const { t } = this.props;

        let lessonState = this.props.completed?
            'completed': this.props.open ?
                'started': 'notStarted'

        let imgClass = {
            completed: 'lessonImgCompleted',
            started: 'lessonImgStarted',
            notStarted: 'lessonImgNotStarted'
        }[lessonState]

        let inactiveLesson = <div className="not-started-lesson-div">
            <div className="lesson-img-wrapper">
                <img src={this.props.icon} className={"lesson-img " + imgClass} alt={this.props.title}/>
            </div>
            <div style={{'color': 'gray', 'fontWeight': 'bold'}}>{t(this.props.title)}</div><br/>
        </div>

        if(this.props.open === false)
            return inactiveLesson

        let normalizedLessonTitle = this.props.title.replace(/ /g, '_').toLowerCase()
        let lessonLink = `/lesson/${this.props.language}/${normalizedLessonTitle}/${this.props.level}`

        return <div className="lesson-div">
            <Link to={lessonLink}
                onClick={
                    ()=>{
                        saveGAEvent({
                            category: 'usage',
                            action: 'lesson started',
                            course: this.props.language,
                            lesson: normalizedLessonTitle,
                            level: this.props.level,
                            user_id: this.props.userProfile.userId
                        })
                        this.loadQuestions(this.props.language, normalizedLessonTitle, this.props.level - 1)
                    }
                }
                >
                <div className="lesson-img-wrapper">
                    <img src={this.props.icon} className={"lesson-img " + imgClass} alt={this.props.title}/>
                </div>
                <div>{t(this.props.title)}</div>
                <center>
                    <ProgressBar progress={this.props.progress}/>
                </center>
                <Level level={this.props.level} max={this.props.maxLevels || 3}/>
            </Link>
        </div>
    }
}


class ProgressBar extends Component {

    render(){

        return <div>
            <div className="progressbar-outer">
                <div className="progress-bar progress-bar-striped small-progress-bar"
                style={{"width": this.props.progress>=99.9? 100: this.props.progress + '%'}}>
                &nbsp;
                </div>
            </div>
        </div>
    }
    //{this.props.progress}%
}

class Level extends Component {

    render(){
        return <div>
            {
                Array(this.props.max).fill().map(
                    (elem, id) => {
                            if(id < this.props.level-1){
                                return <i className="fa star-completed" key={id}/>
                            }
                            return <i className="fa star" key={id}/>
                        }
                    )
            }
        </div>
    }
}


function select (state) {
  return {
    data: state
  }
}

export default connect(select)(
    withTranslation()(
        LessonWidget
    )
);