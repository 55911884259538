import React, { Component } from 'react';

import AceEditor from 'react-ace';

import 'brace/mode/java';
import 'brace/mode/python';
import 'brace/mode/c_cpp';
import 'brace/mode/csharp';
import 'brace/mode/golang';
import 'brace/mode/javascript';
import 'brace/mode/ruby';
import 'brace/mode/scala';
import 'brace/mode/php';
import 'brace/mode/mysql';

import 'brace/ext/whitespace';

import 'brace/theme/github';
import 'brace/theme/textmate';
import 'brace/theme/monokai';
import 'brace/theme/tomorrow';

import './ace_custom_style.css';



class CodeEditor extends Component {
    constructor(props){
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render(){
        //let height = '200px';
        //let height = '95%';
        //let height = this.state.editorHeight;
        var mode = this.props.language.toLowerCase();
        if (mode === 'not selected'){
            mode = 'html';
        }
        if(mode === 'c' || mode === 'c++'){
            mode = 'c_cpp';
        }
        if(mode === 'sql'){
            mode = 'mysql';
        }
        var editor = (
            <div id="editor" className="not-selectable">
                <AceEditor
                    mode={mode}
                    theme="textmate"
                    value={this.props.raw? this.props.value : unescapeHtml(this.props.value)}
                    width='100%'
                    style={
                        {
                            border: '1px solid lightgray',
                            borderRadius: '5px',
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
                        }
                    }
                    fontSize={this.state.width >= 1368 ? 20: 16 /*TODO: resize depending on line count - fewer lines - bigger fontSize*/}
                    maxLines={Infinity}
                    name="aceEdit"
                    highlightActiveLine={false}
                    highlightGutterLine={false}
                    //showGutter={false}
                    markers={this.props.markers || []}
                    editorProps={
                        {
                            $highlightActiveLine: false,
                            $highlightGutterLine: false,
                        }
                    }
                    onChange={this.props.onValueChange}
                    onLoad={(editor) => {
                        editor.renderer.setScrollMargin(10, 10, 10, 10)

                        editor.setOptions({
                            highlightActiveLine: false,
                            highlightGutterLine: false,
                            showPrintMargin: false,
                            autoScrollEditorIntoView: true,
                        })
                        editor.renderer.$highlightGutterLine = false;
                        editor.renderer.$highlightActiveLine = false;
                        editor.commands.commmandKeyBinding={};
                        //editor.getSession().setUseWrapMode(true);
                        editor.setOption("highlightActiveLine", false)
                        editor.setOption("highlightGutterLine", false)
                        editor.setHighlightGutterLine(false);
                        editor.setHighlightActiveLine(false);

                        editor.blur()
                        editor.focus()
                    }}
                    ref={(editor)=>this.editor=editor}
                />
            </div>);
        if(mode === 'php'){
            let customMode = {path:"ace/mode/php", inline:true}
            this.refs.aceEditor.editor.getSession().setMode(customMode);
            //this.refs.aceEditor.editor.insert(" ")
            // it doesn't work instantly, only catches up after a change
        }
        return editor
    }
}


function unescapeHtml(str){
    var map = {amp: '&', lt: '<', le: '≤', gt: '>', ge: '≥', quot: '"', '#039': "'"}
    return str.replace(/&([^;]+);/g, (m, c) => map[c]|| '')
}

export default CodeEditor;
