import { GoogleOAuthProvider } from '@react-oauth/google';

import React, { Component } from 'react';
import Navbar from './common/Navbar';
import BottomBar from './common/BottomBar';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import {connect} from 'react-redux'

class App extends Component {

    componentDidMount(){
        /*FB.getLoginStatus(function(response) {
            statusChangeCallback(response);
        });*/
    }

    render() {
        let googleOauthIDDev = '175103915980-g85mbg45oimtbg5mib51hgu2hf0e06o9.apps.googleusercontent.com'

        return (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_ID? process.env.REACT_APP_GOOGLE_OAUTH_ID: googleOauthIDDev}>
                <div className="App">
                    <div className="content">
                        <Navbar
                            loggedIn={this.props.data.loggedIn}
                            isSending={this.props.data.isSending}
                            history={this.props.history}
                            dispatch={this.props.dispatch}
                            location={this.props.location}
                        />
                        <div className="content">
                        {this.props.children}
                        </div>
                        <BottomBar
                            loggedIn={this.props.data.loggedIn}
                            isSending={this.props.data.isSending}
                            history={this.props.history}
                            dispatch={this.props.dispatch}
                            location={this.props.location}
                        />
                    </div>
                </div>
            </GoogleOAuthProvider>
        )
    }
}


App.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  children: PropTypes.object,
  dispatch: PropTypes.func
}


function select (state) {
  return {
    data: state
  }
}


export default withRouter(
    connect(select)(App)
);
