import React, { Component } from 'react';
import {connect} from 'react-redux'


class ProgressBar extends Component {

    render(){

        return <div>
            <div style={{
                    border: '1px solid lightgray',
                    'borderRadius': '10px',
                    width: this.props.width || '150px',
                    'fontSize': this.props.fontSize || '8px',
                    'textAlign': 'left',
                    'backgroundColor': 'white'}}>
                <div className="progress-bar progress-bar-striped progress-bar-daily-goal"
                style={{"width": this.props.progress + '%'}}>
                &nbsp;
                </div>
            </div>
        </div>
    }
    //{this.props.progress}%
}


function select (state) {
  return {
    data: state
  }
}

export default connect(select)(ProgressBar);