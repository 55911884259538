import React, { Component } from 'react';
import {connect} from 'react-redux'

import LessonWidget from './LessonWidget';
//import history from '../../components/common/history'



/*function forwardTo (location) {
  history.push(location)
}*/


class LessonTree extends Component {

    render() {

        /*console.log(this.props.data.courseInfo)
        console.log(this.props.data.currentCourse)
        console.log(!this.props.data.currentCourse)
        console.log(!this.props.data.courseInfo)*/

        if(!this.props.data.currentCourse || !this.props.data.courseInfo){
            // TODO: not pleasant UX, should keep cached lesson tree in local storage
            return <div
                  className="lesson-tree-widget"
                  style={{'marginTop': '30px', 'textAlign': 'center'}}>

                  Loading...
            </div>
        }


        let language = this.props.data.currentCourse.toLowerCase()

        //console.log(language)

        if(! this.props.data.courseInfo[language]){
            return <div
                  className="lesson-tree-widget"
                  style={{'marginTop': '30px', 'textAlign': 'center'}}>

                  Loading...
            </div>
        }

        /*console.log(this.props.data.courseInfo)
        console.log(language)
        if (!this.props.data.courseInfo[language]){
           forwardTo('/courses')
           return <div>Redirecting to courses</div>
        }*/
        let lessonsLevels = this.props.data.courseInfo[language]['lessons']

        return <div
                  className="lesson-tree-widget"
                  style={{'marginTop': '30px', 'textAlign': 'center'}}>
            {
                lessonsLevels.map((lessonLevel, levelId)=>
                    <div className="row" style={{'marginTop': '20px'}} key={levelId}>
                        {
                            lessonLevel.map(
                                (lesson, key)=> {
                                //console.log(lesson)
                                //console.log(Math.floor(lesson.lessonsCompleted/lesson.totalLessons)*100)
                                if(lesson.type === 'checkpoint'){
                                    return <div className='col-12' key={key}>
                                        <hr/>
                                        <LessonWidget
                                            language={language}
                                            title={lesson.title}
                                            icon={lesson.icon}
                                            open={lesson.open}
                                            completed={lesson.completed}
                                            lessonsCompleted={lesson.lessonsCompleted}
                                            level={lesson.level}
                                            progress={Math.floor(lesson.lessonsCompleted/lesson.totalLessons*100)}
                                        />
                                        <hr/>
                                    </div>
                                }

                                //TODO: only use at most 2 lessons per row, it will be much easier to optimize the layout
                                return <div className={{1: 'col-12', 2: 'col-6', 3: 'col-4'}[lessonLevel.length]} key={key}>
                                    <LessonWidget
                                        language={language}
                                        title={lesson.title}
                                        icon={lesson.icon}
                                        open={lesson.open}
                                        completed={lesson.completed}
                                        lessonsCompleted={lesson.lessonsCompleted}
                                        level={lesson.level}
                                        progress={Math.floor(lesson.lessonsCompleted/lesson.totalLessons*100)}
                                    />
                                    <br/>
                                </div>
                                }
                            )
                        }
                    </div>
                )
            }

            {false? <div className="row">
                <div className="col-lg-12">
                    <div className="final-award">
                        {false?
                            <img src="https://cdn1.iconfinder.com/data/icons/education-and-science-13/32/education_Awards_goblet_prize_sport_success-512.png"
                            src2="https://cdn3.iconfinder.com/data/icons/creative-process-1-1/33/37-512.png"
                            alt="Final award"/>
                            : ''
                        }
                    </div>
                </div>
            </div>: ''}
        </div>
    }
}



function select (state) {
  return {
    data: state
  }
}

export default connect(select)(LessonTree);
