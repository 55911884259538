export const COURSES = [
    {label: 'Python', name: 'python', logo: "/images/course-icons/Python.svg", status: 'alpha'},
    {label: 'HTML/CSS', name: 'html_css', logo: "/images/course-icons/html-css.png", status: 'alpha'},
    {label: 'JavaScript', name: 'javascript', logo: '/images/course-icons/javascript2.png', status: 'alpha'},
    //{label: 'SQL', name: 'sql', logo: 'https://banner2.cleanpng.com/20180526/oqt/kisspng-microsoft-sql-server-mysql-database-logo-5b098c6ebad6d7.7316225815273524307653.jpg', status: 'in development'},
    //{label: 'Machine learning', name: 'machine_learning', logo: 'https://www.kindpng.com/picc/m/186-1862572_noun-machine-learning-1927723-ai-logo-png-transparent.png', status: 'in development'}
    //{label: 'Cobol', name: 'cobol', logo: '/images/course-icons/cobol2.jpg', status: 'alpha'},
    //{label: 'C', name: 'c', logo: '/images/course-icons/c.jpg', status: 'alpha'},
    //{label: 'Java', name: 'java', logo: '', status: 'alpha'},
    // go
    // php
    // lisp
    // C# (?)
    // basic
    // assembly
    // retro programming?
    // prompt engineering?
];


export const GROUPS = [
    {
        name: 'Learning programming with Codilingo',
        description: 'A group where you can have direct impact on our platform, propose features and suggest improvements',
        img: '/images/groups/spaceDog-800.jpg',
        link: 'https://www.facebook.com/groups/learning.programming.codilingo/'
    },
    {
        name: 'Learn Python',
        description: 'A group where you can ask python-related questions and have them answered by experienced mentors',
        img: '/images/groups/wallpaper-caption-adjusted-python-800.jpg',
        link: 'https://www.facebook.com/groups/learn.python.programming/'
    },
    {
        name: 'Programming memes',
        description: 'A fun group where you can upload and browse funny images related to programming and IT',
        img: '/images/groups/dlanham-SpaceDoggy3-800.jpg',
        link: 'https://www.facebook.com/groups/programming.memes/'
    },
    {
        name: 'Learn HTML and CSS',
        description: 'A group where you can ask HTML/CSS related questions and have them answered by experienced mentors',
        img: '/images/groups/html-css-group.jpg',
        link: 'https://www.facebook.com/groups/learn.htmlcss',
    }
]