import React, { Component } from 'react';
import {connect} from 'react-redux'
import { withTranslation } from 'react-i18next';



class SocialFollow extends Component {

   // TODO: add youtube, tiktok, threads, more?

    render() {
        const { t } = this.props;

        return <div className="widgetProfile">
            <center>
            <h2>{t('Follow Codilingo')}</h2>
            <div className="social-buttons">
                <a href="https://facebook.com/codilingo" target="blank">
                    <button className="btn btn-primary btn-lg btn-social btn-fb"><i className="fab fa-facebook-square"></i> Facebook</button>&nbsp;
                </a>

                <a href="https://twitter.com/codilingo" target="blank">
                    <button className="btn btn-primary btn-lg btn-social btn-twitter"><i className="fab fa-twitter"></i> Twitter</button>&nbsp;
                </a>

                <a href="https://instagram.com/codilingo" target="blank">
                    <button className="btn btn-primary btn-lg btn-social btn-instagram"><i className="fab fa-instagram"></i> Instagram</button>
                </a>
            </div>
            <br/>
            </center>
        </div>
    }
}

function select (state) {
  return {
    data: state
  }
}

export default connect(select)(
    withTranslation()(
        SocialFollow
    )
);