import React, { Component } from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';


class Alert extends Component {

    render() {
        return <div class="alert alert-warning alert-dismissible fade show" role="alert">
            {this.props.content}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    }
}


// Which props do we want to inject, given the global state?
function select (state) {
  return {
    data: state
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
    connect(select)(
        withTranslation()(
            Alert
        )
    )
);
