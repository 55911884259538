import React, { Component } from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import history from './history';

import { withTranslation } from 'react-i18next';
import {removeAuthToken} from '../../auth/token';



function forwardTo (location) {
  history.push(location)
}


class UserProfileDropdown extends Component {

    constructor (props) {
        super(props);
    }

    render(){
        const { t } = this.props;

        return <div className="btn-group course-dropdown-div">
            <button
                type="button"
                className="btn btn-secondary dropdown-toggle course-dropdown"
                data-toggle="dropdown"
                data-display="static"
                aria-haspopup="true"
                aria-expanded="false"
                style={{border: 'none'}}>
                <img
                    alt={this.props.data.userProfile.nick}
                    src={this.props.data.userProfile.avatar}
                    className="avatar-img"/>

            </button>
            <div className="dropdown-menu dropdown-menu-lg-right dropdown-menu-right">
                <Link to="/profile">
                    <button className="dropdown-item" type="button">
                        <i className="bi bi-person-circle"></i>
                            <Link to="/profile" className="dropdown-text-item">
                                {t('Profile')}
                            </Link>
                    </button>
                </Link>

                <hr/>
                <button className="dropdown-item" type="button" onClick={
                        (e)=>{
                          //e.preventDefault();
                          console.log('remove auth token')
                          removeAuthToken();
                          console.log('window.FB.logout')
                          window.FB.logout();
                          console.log('this.props.history.push("/auth")')
                          this.props.history.push("/auth");
                        }
                    }>
                    <i className="bi bi-power"></i>
                    <a className="dropdown-text-item" href="/">
                        {t('Logout')}
                    </a>
                </button>
            </div>
        </div>
    }
}


// Which props do we want to inject, given the global state?
function select (state) {
  return {
    data: state
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
    connect(select)(
        withTranslation()(
            UserProfileDropdown
        )
    )
);
