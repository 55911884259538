import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { api } from '../../api'


import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);


class Leaderboard extends Component {
    render() {
        const { t } = this.props;

        this.props.data.leaderboard = [
            //{slug: 'jan', avatar: 'https://www.gravatar.com/avatar/604d8c4bb4ac807452e28968672d?d=robohash&s=200', nick: 'Jan', exp: 200},
            //{slug: 'zosia', avatar: 'https://www.gravatar.com/avatar/604d8c4bb4a07452e28968672d?d=robohash&s=200', nick: 'Zosia', exp: 1200},
            //{slug: 'slawek', avatar: 'https://www.gravatar.com/avatar/604d4bb4ac807452e28968672d?d=robohash&s=200', nick: 'Sławek', exp: 3200},
            {slug: 'gosia', avatar: 'https://www.gravatar.com/avatar/604d8c4bb4ac807452e289672d?d=robohash&s=200', nick: 'Gosia', exp: 500},
            {slug: 'pawel', avatar: 'https://www.gravatar.com/avatar/604d8c4bb4ac8074e28968672d?d=robohash&s=200', nick: 'Paweł', exp: 700},
        ]

        this.props.data.leaderboard = []

        //zamiast Find zrobić kolejny wiersz "friend" z ikonką add (+)

        return <div className="widgetProfile">
            <center><h2 className="widgetTitle">{t('Friends')}</h2></center>

            <div style={{'maxHeight': '200px', 'minHeight': '50px', 'overflowY': 'auto'}}>
                {
                    this.props.data.leaderboard.map( (user, key) => (
                        <div style={{"border": "1px solid lightgray"}} key={key}>
                            <Link to={"/profile/" + user.slug}>
                                <img
                                    src={user.avatar}
                                    style={{'width': '50px', height: '50px', 'borderRadius': '25px'}}
                                    alt={user.slug}/>
                            </Link>
                            &nbsp; <c style={{'fontSize': '14px'}}><b>{user.nick}</b> &nbsp; {user.exp}exp</c>
                        </div>)
                    )
                }
                {
                    this.props.data.friends.length === 0?
                        <div style={{color: 'gray'}}>
                            {false&&t('Currently you have no friends in Codilingo.')}<br/>
                            <center>{t('Complete a lesson to join weekly leaderboards!')}</center><br/>
                        </div>
                        : ''
                }
            </div>
            <center>
                <button className="btn btn-primary btn-lg btn-normal" onClick={() => showInvitePopup(t)}>Invite</button>&nbsp;
                {false&&<button className="btn btn-primary btn-lg btn-normal">Find</button>}
            </center>
            <br/>
        </div>
    }
}


function showInvitePopup(t){
    //const {t, i18n} = props
    MySwal.fire({
      title: <p>{t('Invite your friend to Codilingo!')}</p>,
      //text: t('Email, message'),
      html: '<input id="swal-input-email" class="swal2-input" placeholder="Enter email" type="email" required>' +
            '<textarea id="swal-input-message" aria-label="Type your message here" class="swal2-textarea"'+
            'style="display: flex;" placeholder="Type your message here (optional) ..."></textarea>',
      /*input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },*/
      showLoaderOnConfirm: true,

      showCancelButton: true,
      confirmButtonText: <span>OK</span>,
      cancelButtonText: <span>{t('Cancel')}</span>,

      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!'
        }
      },

      preConfirm: (login) => {
        /*return fetch(`https://api.github.com/users/${login}`)
           .then(response => {
              if (!response.ok) {
                throw new Error(response.statusText)
              }
              return response.json()
           })
           .catch(error => {
                MySwal.showValidationMessage(
                  `Request failed: ${error}`
                )
           })*/

          let email = document.getElementById("swal-input-email").value
          let message = document.getElementById("swal-input-message").value

          let email_regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
            //  [a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?
            // enough?
            // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript

          if( !email_regex.test(email) ){
            return MySwal.showValidationMessage(
              `Please provide a correct email. ${email} is not a valid email.`
            )
          }

        return api.inviteFriend(email, message)
          .then(response => {
            if(response.status===405){
               throw new Error('Cannot invite ' + email + ', already registered.')
            }
            if(response.status===406){
               throw new Error('Invitation limit exceeded.')
            }
            if(response.status===407){
               throw new Error('Cannot invite ' + email)
            }
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            MySwal.showValidationMessage(
              //`Request failed: ${error}`
              error.message
            )
          })
      },
      allowOutsideClick: () => !MySwal.isLoading()
    }).then(
        (result) => {
          if (result.value !== undefined) {
            /*MySwal.fire({
              title: `${result.value.login}'s avatar`,
              imageUrl: result.value.avatar_url
            })*/
            MySwal.fire({
              type: 'success',
              text: t('Thank you for inviting your friend!'),
            })


          }
        }
    )
}


function select (state) {
  return {
    data: state
  }
}

export default connect(select)(
    withTranslation()(
        Leaderboard
    )
);