import React, { Component } from 'react';


class FakeBrowser extends Component {

    // TODO: use iframe instead of a div?

    render(){

        return (
            <div
                className="reset-this-parent">
                <div style={{
                        boxShadow: '#c8c8c8 1px 3px 10px 1px',
                        height: '100%',
                        }}>
                    <div style={{
                            backgroundColor: 'lightblue',
                            border: '1px solid gray',
                            borderTopLeftRadius: '5px',
                            borderTopRightRadius: '5px',
                            padding: '3px'}}>
                        <span className="dot" style={
                            {
                              height: '15px',
                              width: '15px',
                              backgroundColor: 'red',
                              borderRadius: '50%',
                              display: 'inline-block',
                              margin: '0px 2px 0px 2px'
                            }
                        }></span>
                        <span className="dot" style={
                            {
                              height: '15px',
                              width: '15px',
                              backgroundColor: 'orange',
                              borderRadius: '50%',
                              display: 'inline-block',
                              margin: '0px 2px 0px 2px'
                            }
                        }></span>
                        <span className="dot" style={
                            {
                              height: '15px',
                              width: '15px',
                              backgroundColor: 'green',
                              borderRadius: '50%',
                              display: 'inline-block',
                              margin: '0px 2px 0px 2px'
                            }
                        }></span>
                        <div style={{margin: '2px', display: 'inline-block'}}>{extractTitle(this.props.html) || 'Codilingo browser'}</div>
                    </div>
                    <div style={{
                            backgroundColor: 'lightgray',
                            border: '1px solid gray',
                            padding: '3px',
                            display: 'flex'}}>
                        <button style={{display: 'inline'}}> <img alt="back" style={{height: '15px', width:'15px', transform: 'rotateY(180deg)'}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAclBMVEX///8AAACXl5eFhYX8/Pxra2vr6+skJCTo6Oi2trb4+Pjw8PBGRkbh4eG5ubnY2NjJycmLi4syMjLAwMCdnZ03Nzeqqqp1dXUqKirOzs5VVVUKCgp/f39bW1tNTU0bGxsUFBRlZWU+Pj6mpqacnJyTk5Pk/AcmAAADI0lEQVR4nO3dCXLiMBCF4TTEhN3s+5aQ3P+Kw8zUZEjYvEgl6+X/DkD1Swy4Lal5egIAAAAAAAAAAAAAAAAAAAAAAFF6boeuwLvpLHQFvtXtLQ1dg199M5tLX6oN++0YugyfRn8iLpah6/Ansb+SRuhKfJnZP7XQpXiSfia0l0HoYvywM9tm6Gp82J5HtI/n0PW4V/uS0F7GoQtybmnfrNQu1efvCc2GYpfq6DKitUIX5VT9SkLrKt2Pj68lNNvo3I/3ric002kd97cijlQu1eRWwlPr2AldnBOz2wlFWsebb0SZS7V9uBvRkvgv1dX9hGbvoSssq/YooS0ibx0HDxOavUb9lKOdIeGpdQxdZhndTBHXEbeOm0wJT61jL3SlRfUzJoy3dWxkTmiHfuhii1lnj2hdj5fq5C0Z1mbjtNd03bvNcyT02Tr+/2Zev4x2yfB4StvotB28Ne7efF9a+2odb9x7LLqrpH5sjZfNwlnT6698m6dVx8d3V7bvvs7rk/4gzZk2b0JPq44ZEn4x2m6Gk9Yy7T1uDnb5I/poHfMmPLOfbue1SWvQa3au/u0LvfTI+apjiYRfwna384/ZKe3Zh9TFk+9sXLeObhKeO0xXr6e040GW9uIqt62j+4QOOF11rGRCp6uOFU3osHWsbEJnq47VTehq1bHKCd20jtVO6KJ1rHhCs13Z1rHyCc3q5S7VCBKWXCCPImGp1jGOhGVWHWNJWLx1jCdh0VXHiBIWbB2jSmg2kU9Y4ClHbAnzrzrGl9As331cfAnV/4fy70P1z1L170P5exr1+1L13kK+P1Tv8dWf06g/a5N/Xqr+zFt93UJ+7Ul9/VB9Dbji6/jr0nsxCjSB3hN+7qdJpfbTiO6JktzXprk3UXJ/qeoeYf193v5UZa++N/rnLfTPzOife5I/u6Z//lD/DKn+OWD5s9z65/H1Zyroz8XQn20iP59Gf8aQ/pwo/Vlf8vPa9Gfu6c9N1J99qT+/9Dyf5Axa/TnC+rOg9ed5y89k15+rr//bCPq/byH/GyU/4HdmAAAAAAAAAAAAAAAAAAAAAAD4GX4BoIZCNkoWdWgAAAAASUVORK5CYII="/> </button>
                        <button style={{display: 'inline'}}> <img alt="forward" style={{height: '15px'}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAclBMVEX///8AAACXl5eFhYX8/Pxra2vr6+skJCTo6Oi2trb4+Pjw8PBGRkbh4eG5ubnY2NjJycmLi4syMjLAwMCdnZ03Nzeqqqp1dXUqKirOzs5VVVUKCgp/f39bW1tNTU0bGxsUFBRlZWU+Pj6mpqacnJyTk5Pk/AcmAAADI0lEQVR4nO3dCXLiMBCF4TTEhN3s+5aQ3P+Kw8zUZEjYvEgl6+X/DkD1Swy4Lal5egIAAAAAAAAAAAAAAAAAAAAAAFF6boeuwLvpLHQFvtXtLQ1dg199M5tLX6oN++0YugyfRn8iLpah6/Ansb+SRuhKfJnZP7XQpXiSfia0l0HoYvywM9tm6Gp82J5HtI/n0PW4V/uS0F7GoQtybmnfrNQu1efvCc2GYpfq6DKitUIX5VT9SkLrKt2Pj68lNNvo3I/3ric002kd97cijlQu1eRWwlPr2AldnBOz2wlFWsebb0SZS7V9uBvRkvgv1dX9hGbvoSssq/YooS0ibx0HDxOavUb9lKOdIeGpdQxdZhndTBHXEbeOm0wJT61jL3SlRfUzJoy3dWxkTmiHfuhii1lnj2hdj5fq5C0Z1mbjtNd03bvNcyT02Tr+/2Zev4x2yfB4StvotB28Ne7efF9a+2odb9x7LLqrpH5sjZfNwlnT6698m6dVx8d3V7bvvs7rk/4gzZk2b0JPq44ZEn4x2m6Gk9Yy7T1uDnb5I/poHfMmPLOfbue1SWvQa3au/u0LvfTI+apjiYRfwna384/ZKe3Zh9TFk+9sXLeObhKeO0xXr6e040GW9uIqt62j+4QOOF11rGRCp6uOFU3osHWsbEJnq47VTehq1bHKCd20jtVO6KJ1rHhCs13Z1rHyCc3q5S7VCBKWXCCPImGp1jGOhGVWHWNJWLx1jCdh0VXHiBIWbB2jSmg2kU9Y4ClHbAnzrzrGl9As331cfAnV/4fy70P1z1L170P5exr1+1L13kK+P1Tv8dWf06g/a5N/Xqr+zFt93UJ+7Ul9/VB9Dbji6/jr0nsxCjSB3hN+7qdJpfbTiO6JktzXprk3UXJ/qeoeYf193v5UZa++N/rnLfTPzOife5I/u6Z//lD/DKn+OWD5s9z65/H1Zyroz8XQn20iP59Gf8aQ/pwo/Vlf8vPa9Gfu6c9N1J99qT+/9Dyf5Axa/TnC+rOg9ed5y89k15+rr//bCPq/byH/GyU/4HdmAAAAAAAAAAAAAAAAAAAAAAD4GX4BoIZCNkoWdWgAAAAASUVORK5CYII="/> </button>
                        <button style={{display: 'inline'}}> <img src="/images/refresh.png" alt="refresh" style={{height: '15px'}}/> </button>
                        <input type="text" value="index.html" style={{width: '90%', display: 'inline'}}/>
                    </div>
                    <div
                        style={{
                            border: '1px solid gray',
                            backgroundColor: 'white',
                            'minHeight': '200px',
                            'height': '200px', //TODO: change to 100%
                            display: 'table',
                            'width': '100%',
                            resize: 'both'
                        }}>
                        <div style={{height: '100%', width: '100%', overflow: 'hidden', display: 'table-row'}}>
                            <iframe
                                title="fake-browser"
                                srcdoc={this.props.html.replace('img src', 'img style="max-width:200px" src')} //TODO: this is hack for having better visible images for the users
                                style={{
                                    width: '100%', height: '100%', border: 'none', margin: 0, padding: 0, display: 'block',
                                    '-webkit-background-size': 'cover', '-moz-background-size': 'cover', '-o-background-size': 'cover', 'background-size': 'cover'
                                }}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//                            dangerouslySetInnerHTML={{__html: this.props.html}}



function extractTitle(html){
    const doc = new DOMParser().parseFromString(html, "text/html");
    /*console.log(html);
    console.log(doc);*/
    return doc.title
}


export default FakeBrowser;