import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import { TiSocialFacebookCircular } from 'react-icons/ti';

//import { GoogleLogin } from 'react-google-login';
import { AiFillGoogleCircle } from 'react-icons/ai';

import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

import { registerOrLogin } from '../../actions';
import { withTranslation } from 'react-i18next';

import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';

import jwt_decode from "jwt-decode";



// https://infinum.com/blog/how-to-use-react-hooks-in-class-components/
const withGoogleLoginHookHOC = (Component: any, onSuccess) => {
  return (props: any) => {
    const googleLoginHook = useGoogleLogin({
        onSuccess: onSuccess,
        //onFailure: response => {console.log(response)},
        flow: 'auth-code'
    });

    return <Component onClick={googleLoginHook} {...props} />;
  };
}


const CustomGoogleLoginButton = (renderProps) => (
    <button className="my-google-button-class" onClick={renderProps.onClick} disabled={renderProps.disabled}>
        <AiFillGoogleCircle/>&nbsp; {renderProps.buttonText}
    </button>
)


// TODO: handle passing invitationHash if used with this path /join/:invitationHash
class Auth extends Component {

    constructor(props){
        super(props)
        this.facebookLoginCallback = this.facebookLoginCallback.bind(this)
        this.googleLoginCallback = this.googleLoginCallback.bind(this)

        this.state = {
            view: 'initial' //  initial, registerForm, loginForm
        }
    }

    facebookLoginCallback(response){
        //console.log(response)


        this.props.dispatch(
            {
                type: 'SET_GENERIC',
                isLoggedIn: true,
                userID: response.userID,
                name: response.name,
                email: response.email,
                profilePicture: response.picture_url
            }
        )

       //if response ok dispatch login action
        this.props.dispatch(
            registerOrLogin({
                source: 'facebook',
                ...response
            })
        )
    }

    googleLoginCallback(response){
        //console.log(response)

        this.props.dispatch(
            registerOrLogin({
                source: 'google',
                ...response
            })
        )
    }

    render(){
        const { t } = this.props;


        //http://getwallpapers.com/wallpaper/full/f/4/a/90957.jpg
        //https://backgroundcheckall.com/wp-content/uploads/2017/12/cartoon-space-background-11.jpg

        //https://martechtoday.com/wp-content/uploads/2018/08/martech-machine-learning-sean-zinsmeister_ltazzg.jpg
        //https://i.pinimg.com/originals/ff/16/3a/ff163a05b2370d38633911f965f06430.jpg

        //https://www.sebalter.net/u/2019/06/space-alien-drawings-boy-tumblr-astronaut-between-908x875.jpg
        //https://images.wallpaperscraft.com/image/fog_field_sunrise_125708_2560x1024.jpg

        let registerButtons = <div>
            <button className="btn btn-primary btn-highlight btn-login btn-fb">{t('Login with Facebook')}</button>
            <button className="btn btn-primary btn-highlight btn-login btn-highlight-red">Login with Google</button>
        </div>

        //Continue with Facebook/Google instead of register/login?

        /*
                        <a href='/learn'><button className="btn btn-primary btn-highlight btn-login">Register</button></a><br/>
                <a href='/learn'><button className="btn btn-primary btn-highlight btn-login-empty">I already have an account</button></a>
        */
        //let fbButton = <div class="fb-login-button" data-width="" data-size="large" data-button-type="continue_with" data-auto-logout-link="false" data-use-continue-as="true"></div>
        //let fbAppIdProd = '718414922012671'
        let fbAppIdDev = '2541275996154906'


        let fbButton = <FacebookLogin
            appId={process.env.REACT_APP_FB_APP_ID? process.env.REACT_APP_FB_APP_ID: fbAppIdDev}
            autoLoad={false}
            fields="name,email,picture.width(250)"
            //onClick={componentClicked}
            callback={this.facebookLoginCallback}
            cssClass="my-facebook-button-class"
            textButton={t('Login with Facebook')}
            icon={<TiSocialFacebookCircular/>}/>

        let passwordRegisterButton = <Link to='/auth'>
            <button className="btn btn-primary btn-highlight btn-login" onClick={()=>this.setState({'view': 'registerForm'})}>
                Register using password
            </button>
        </Link>

        let loginButton = <Link to='/auth'>
            <button className="btn btn-primary btn-highlight btn-login-empty" onClick={()=>this.setState({'view': 'loginForm'})}>
                I already have an account
            </button>
        </Link>

        let googleOauthIDDev = '175103915980-g85mbg45oimtbg5mib51hgu2hf0e06o9.apps.googleusercontent.com'
        //let googleOauthIDProd = '516302610481-9gk04e021r4nbtnjv7ok5gq6tjh4f2ct.apps.googleusercontent.com'

        let googleButton = <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_ID? process.env.REACT_APP_GOOGLE_OAUTH_ID: googleOauthIDDev}
            buttonText={t("LOGIN WITH GOOGLE")}
            onSuccess={this.googleLoginCallback}
            onFailure={this.googleLoginCallback}
            className="my-google-button-class"
            render={renderProps => (
              <button className="my-google-button-class" onClick={renderProps.onClick} disabled={renderProps.disabled}>
              <AiFillGoogleCircle/>&nbsp;Login with Google</button>
            )}
            cookiePolicy={'single_host_origin'}
        />

        const googleLoginOnSuccessCallback = (response) => {
            console.log("googleLoginonSuccessCallback")
            console.log(response);
            let decoded = jwt_decode(response.credential);
            console.log("decoded jwt:")
            console.log(decoded);
            this.googleLoginCallback(response);
        }

        const googleLoginOnSuccessCallbackCustom = (response) => {
            console.log("googleLoginonSuccessCallbackCustom")
            console.log(response);
            this.googleLoginCallback(response);
        }

        let googleButtonNew = <GoogleLogin
            //clientId={process.env.REACT_APP_GOOGLE_OAUTH_ID? process.env.REACT_APP_GOOGLE_OAUTH_ID: googleOauthIDDev}
            //buttonText={t("LOGIN WITH GOOGLE")}
            onSuccess={googleLoginOnSuccessCallback}
            onFailure={()=>{}}
            text={"continue_with"}
            size={"large"}
            width={"300"}
            text={t("LOGIN WITH GOOGLE")}
            //className="my-google-button-class"
            //render={renderProps => (
            //  <button className="my-google-button-class" onClick={renderProps.onClick} disabled={renderProps.disabled}>
            //  <AiFillGoogleCircle/>&nbsp;Login with Google</button>
            //)}
            cookiePolicy={'single_host_origin'}
        />

        let LoginWithGoogleComponent = withGoogleLoginHookHOC(CustomGoogleLoginButton, googleLoginOnSuccessCallbackCustom);
        let googleButtonUseGoogleLogin = <LoginWithGoogleComponent
            buttonText={t("LOGIN WITH GOOGLE")}
        />

        let initialComponent = <div className="col-lg-4 colLoginButtons">
                {false? registerButtons: ''}
                {fbButton}<br/>
                {false? googleButton: ''}<br/>
                <div className="googleLoginButton">{googleButtonNew}</div><br/>
                {false?googleButtonUseGoogleLogin: ""}<br/>
                {false?<div><input type="checkbox"/> <b style={{color:"white"}}>I agree to receive emails about updates</b></div>:""}
                {false?passwordRegisterButton:''}
                {false?loginButton:''}

            </div>

        let registerWithPasswordComponent = <RegisterForm/>
        let loginComponent = <LoginForm/>

        let stateToComponent = {
            'initial': initialComponent,
            'registerForm': registerWithPasswordComponent,
            'loginForm': loginComponent
        }

        return <div style={{
            /*'background': 'url(/images/moon-and-earth-in-space2.jpg)',*/
            /*background: "url('https://www.citynews1130.com/wp-content/blogs.dir/sites/9/2018/05/27/iStock-697020460.jpg')",*/
            /*background: "url(http://sf.co.ua/14/01/wallpaper-955931.jpg)",*/
            background: "url(/images/spaceDog.jpg)",
            'backgroundSize': 'cover',
            'height': '100%'
            }}>
          <div className='row logoRow'>
            <div className="col-lg-8">
                <h1 className="titleLogo"><b>Codilingo</b></h1>
                <div className="centered" >
                    <img className="startImgComputer" src="/images/start-computer-2.png" alt="Codilingo on Laptop"/>
                </div>
                <h3 className="subtitleLogo">{t('Learn programming in 5 minutes a day')}</h3>
            </div>
            {stateToComponent[this.state.view]}
          </div>


        </div>
    }
}
        //<img className="startImgComputer" src="/images/start-computer-2.png" src2="https://www.enovatio.com/media/1332/start-computer.png"/>

        /*  additional buttons
                        <Link to='/learn'><button className="btn btn-primary btn-highlight btn-login">Register</button></Link><br/>
                <Link to='/learn'><button className="btn btn-primary btn-highlight btn-login-empty">I already have an account</button></Link>
        */

        /*let googleButton = <GoogleLogin
            clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
            buttonText="LOGIN WITH GOOGLE"
            onSuccess={this.googleLoginCallback}
            onFailure={this.googleLoginCallback}
            className="my-google-button-class"
            render={renderProps => (
              <button className="my-google-button-class" onClick={renderProps.onClick} disabled={renderProps.disabled}> <AiFillGoogleCircle/>&nbsp;Login with Google</button>
            )}
            cookiePolicy={'single_host_origin'}
        />*/


function select (state) {
  return {
    data: state
  }
}

export default connect(select)(
    withTranslation()(
        Auth
    )
);