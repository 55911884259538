import React, { Component } from 'react';
import {connect} from 'react-redux'
import { withTranslation } from 'react-i18next';
import saveGAEvent from '../../common/events';
import {GROUPS} from './../common/constants';


class RecommendedGroups extends Component {
    /*
        TODO: make recommended groups dependent on the current course
    */
    render() {
        const { t } = this.props;

        let groups = GROUPS;


        return <div className="widgetProfile widgetProfileBorder">
            <center style={{'paddingBottom': '20px'}}><h2 className="widgetTitle">{t('Join our FB groups')}</h2></center>


            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="10000" style={{'height': '250px'}}>
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              </ol>
              <div className="carousel-inner">

                {
                    groups.map( ( fbGroup, id) => {

                        return <div className={id===0? "carousel-item active": "carousel-item"} key={id}>
                              <a href={fbGroup.link} target="blank" onClick={
                                    ()=>saveGAEvent({
                                        'category': 'promotion',
                                        'action': 'clicked group link',
                                        'link': fbGroup.link,
                                        'name': fbGroup.name,
                                        user_id: this.props.data.userProfile.userId
                                    })}>
                                <img src={fbGroup.img} className="group-img" alt={fbGroup.name}/>

                              <div className={false?"carousel-caption d-none d-md-block": "carousel-caption d-md-block"}>
                                <h5 className="shadow-text">{fbGroup.name}</h5>
                                <p className="shadow-text group-description">{t(fbGroup.description)}</p>
                              </div>
                              </a>
                            </div>
                    })
                }
              </div>

              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>


        </div>
    }
}




function select (state) {
  return {
    data: state
  }
}

export default connect(select)(
    withTranslation()(
        RecommendedGroups
    )
);