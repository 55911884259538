import React, { Component } from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import history from './history';

import CourseDropdown from './CourseDropdown';
import UserProfileDropdown from './UserProfileDropdown';
import Score from '../widgets/Score';

import { withTranslation } from 'react-i18next';



class Navbar extends Component {

    render(){
        if(window.location.href.includes('initial-test')){
            return <PromoNavbar {...this.props}/>
        }
        return <ProdNavbar {...this.props}/>
    }
}



class ProdNavbar extends Component {

    constructor (props) {
        super(props)
        this.state = {showAlert: false}
    }

    //TODO: HTML/CSS low width: layout breaks
    render() {
        const { t } = this.props;

        let shouldDisplay = !window.location.href.includes('lesson') && !window.location.href.includes('auth');

        let streak = this.props.data.userProfile.streakDays || 0
        let expToday = this.props.data.userProfile.expGainedToday || 0
        /*
        <img src="http://www.cidepix.com/wp-content/uploads/symbiostock_rf_content/3181-colourful-abstract-icon.jpg" className="startImg"/>

        <img src="http://www.androidappsforpc.org/wp-content/uploads/2015/12/3-Circles-Icon.png" className="startImg"/>
        <img src="https://webstersacademy.com/wp-content/uploads/2016/11/Learn-to-think-icon.jpg" className="startImg"/>

        */

        let streakImageStyle = {}
        if(streak === 0 || expToday < this.props.data.userProfile.dailyGoal){
            streakImageStyle['filter'] = 'grayscale(100%)'
        }

        //console.log(this.props.location)
        if(!shouldDisplay)
            return <div/>


        return (
            <div className="navbar" >
                <div className="logo">
                    <a href="https://codilingo.com" target="_blank" rel="noopener noreferrer">
                      <img src="/images/logo2.png" alt="Codilingo" className="main-logo-img"/>
                    </a>
                </div>

                <div className="right-section">
                    <CourseDropdown/>
                    <StreakComponent
                        t={t}
                        streakDays={streak}
                        expToday={this.props.data.userProfile.expGainedToday}
                        dailyGoal={this.props.data.userProfile.dailyGoal}
                    />

                    <GemsComponent
                        t={t}
                        gems={this.props.data.userProfile.bits}
                    />

                    <HeartsComponent
                        t={t}
                        hearts={this.props.data.userProfile.hearts}
                    />
                </div>
            </div>
        );
    }

}



class StreakComponent extends Component {
    constructor (props) {
        super(props);
    }

    render() {
        const { t } = this.props;

        let streak = this.props.streakDays || 0
        let expToday = this.props.expToday || 0

        let streakImageDailyGoalMetStyle = {'width': '35px', height: '30px'}
        let streakImageDailyGoalNotMetStyle = {'width': '35px', height: '30px', 'filter': 'grayscale(100%)'}

        let streakImageStyle = streakImageDailyGoalMetStyle;
        if(streak === 0 || expToday < this.props.dailyGoal){
            streakImageStyle = streakImageDailyGoalNotMetStyle;
        }

        /* TODO: link to streak view
                    <div className="counter dropdown">
                <Link to="/streak">
                    <button type="button" className="dropdown" style={{"border": "none", "backgroundColor": "transparent"}}>
                        <img src="/images/icons/campfire.png" alt="Streak:" style={streakImageStyle}/>
                        <div className="number">{streak}</div>
                    </button>
                </Link>
            </div>
        */

        return (
            <div className="counter dropdown">
                <button type="button" className="dropdown" style={{"border": "none", "backgroundColor": "transparent"}}>
                    <img src="/images/icons/campfire.png" alt="Streak:" style={streakImageStyle}/>
                    <div className="number">{streak}</div>
                </button>
            </div>
        )
    }
}


class GemsComponent extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const { t } = this.props;

        let gems = this.props.gems || 0;

        /* TODO:

                    <div className="gem dropdown">
                <Link to="/store">
                    <button type="button" className="dropdown" style={{"border": "none", "backgroundColor": "transparent"}}>
                        <img src="/images/icons/gem.png" className="gems"/>
                        <div className="number"> {gems} </div>
                    </button>
                </Link>
            </div>

        */

        return (
            <div className="gem dropdown">
                <button type="button" className="dropdown" style={{"border": "none", "backgroundColor": "transparent"}}>
                    <img src="/images/icons/gem.png" className="gems"/>
                    <div className="number"> {gems} </div>
                </button>
            </div>
        );
    }

}


class HeartsComponent extends Component {
    constructor(props){
        super(props);
    }

    getHearts(hearts, maxHearts){
        let style = {'height': '20px', width: '20px'}
        let styleEmpty = {'height': '20px', width: '20px', 'filter': 'grayscale(100%)'}

        return <div>
              {[...Array(maxHearts).keys()].map((i) => (
                <img
                  key={i}
                  src='/images/icons/heart.png'
                  style={i + 1 > hearts ? styleEmpty: style}
                  alt={`Image ${i + 1}`}
                />
              ))}
            </div>

    }

    render() {
        const { t } = this.props;

        let hearts = this.props.hearts || "∞"

        /*
                    <div className="heart dropdown dropdown-toggle">

                        <button
                    type="button"
                    data-toggle="dropdown"
                    data-display="static"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{border: 'none', "backgroundColor": "transparent", display: 'flex'}}>
        */

        return (
            <div className="heart dropdown">
                <button
                    type="button"
                    data-toggle="dropdown"
                    data-display="static"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{border: 'none', "backgroundColor": "transparent", display: 'flex'}}>
                        <img src="/images/icons/heart.png"/>
                        <div className="number"> {hearts} </div>
                </button>
                {false ? <div className="dropdown-menu dropdown-menu-right" style={{'width': '300px'}}>
                    <center><div style={{'padding': '10px'}}>
                        {this.getHearts(hearts, 5)}
                        <div> You have {hearts} hearts left </div>
                        <div> Next heart in 4:00 hours </div>
                        <button className='btn btn-primary'>Practice to get hearts back </button>
                    </div></center>
                </div>: ''}
            </div>
        );
    }

}



class PromoNavbar extends Component {

    constructor (props) {
        super(props)
        this.state = {showAlert: false}
    }

    render() {
        const { t } = this.props;





        return (
            <div className="navbar-wrapper" >
                <div className="row margin-reset navbar">
                    <div className="navbar-content">
                        <div className="nav-col nav-col-logo">
                          <div className="main-logo">
                            <a href="https://codilingo.com" target="_blank" rel="noopener noreferrer">
                              <img src="/images/logo2.png" alt="Codilingo" className="main-logo-img"/>
                            </a>
                          </div>
                        </div>

                        <div className="nav-col nav-col-menu pull-right nav-buttons">
                            <Link to="/auth">
                                <button className="btn btn-lg btn-primary">{t('Learn programming with Codilingo!')}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}




// Which props do we want to inject, given the global state?
function select (state) {
  return {
    data: state
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
    connect(select)(
        withTranslation()(
            Navbar
        )
    )
);
