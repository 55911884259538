import i18n from 'i18next';
//import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

//import translationEng from "./locales/en/translation.json";
//import translationGer from "../locales/ger/translation.json";
//import translationPl from './locales/pl/translation.json';


const lessonsHTML_PL = {
    'document structure': 'struktura dokumentu',
    'basic tags': 'podstawowe tagi',
    'links': 'linki',
    'images': 'obrazki',
    'text tags': 'tagi tekstowe',
    'tables': 'tabelki',
    'lists': 'listy',
    'meta tags': 'tagi meta',
    'forms': 'formularze',
    'checkpoint HTML': 'punkt kontrolny HTML',
    'css basics': 'podstawy css',
}

const questionsHTML_PL = {
    'What are the attributes of the given class `{class_name}`?': 'Jakie są atrybuty klasy `{{class_name}}`?',
    'What are the methods of the given class `{class_name}`?': 'Jakie są metody klasy `{{class_name}}`?',

    'What is the title of this HTML document?': 'Jaki jest tytuł tego dokumentu HTML?',
    'What does this HTML document render to the screen?': 'Co wyświetli się w treści wyrenderowanego dokumentu HTML?',
    'What is the first paragraph in this page?': 'Jaka jest treść pierwszego paragrafu na tej stronie?',
    'What is the second paragraph in this page?': 'Jaka jest treść drugiego paragrafu na tej stronie?',
    'How many lines of text do we have in the rendered page?': 'Ile linii tekstu znajduje się na wyrenderowanej stronie?',
    'How many paragraphs are in this page?': 'Ile paragrafów tekstu znajduje się na tej stronie?',
    'What is the first sentence in this page?': 'Jakie jest pierwsze zdanie na tej stronie?',
    'What is the second sentence in this page?': 'Jakie jest drugie zdanie na tej stronie?',

    'What is the page where the link leads?': 'Do jakiej strony prowadzi ten link?',
    'Where does the first hyperlink in this page lead to?': 'Gdzie prowadzi pierwszy link na tej stronie?',
    'Where does the second hyperlink in this page lead to?': 'Gdzie prowadzi drugi link na tej stronie?',
    'What is the displayed text of the link?': 'Jaki jest tekst linku?',

    'What is the url of the second image?': 'Jaki jest url drugiego obrazka?',
    'There is no second image': 'Nie ma drugiego obrazka',
    'What is the text that will be displayed if the second image is not found?': 'Jaki tekst będzie wyświetlony jeśli drugi obrazek nie zostanie znaleziony?',
    'What is the text that will be displayed if the first image is not found?': 'Jaki tekst będzie wyświetlony jeśli pierwszy obrazek nie zostanie znaleziony?',
    'What is the image alternative text for the first image?': 'Jaki jest tekst alternatywny dla pierwszego obrazka?',
    'What is the image alternative text for the second image?': 'Jaki jest tekst alternatywny dla drugiego obrazka?',
    'How many images are there rendered on the website?': 'Ile obrazków jest wyrenderowanych na stronie?',
    'What is the url of the first image?': 'Jaki jest url pierwszego obrazka?',
    'What is the image url?': 'Jaki jest url obrazka?',

    'What is the highlighted text?': 'Jaka jest treść wyróżnionego tekstu?',
    'What is the name of effect applied to the highlighted text?': 'Jaka jest nazwa efektu zastosowanego na wyróżnionym tekście?',

    'How many columns are there in the table?': 'Ile kolumn znajduje się w tabeli?',
    'How many elements are there in the table? (excluding headers and footers)': 'Ile elementów jest w tabeli? (wyłączając header i footer)',
    'What is the value in row 2 and column 1? (excluding headers and footers)': 'Jaka jest wartość komórki w 2 wierszu i 1 kolumnie? (wyłączając header i footer)',
    'What is the value in row 1 and column 3? (excluding headers and footers)': 'Jaka jest wartość komórki w 1 wierszu i 3 kolumnie? (wyłączając header i footer)',
    'What is the value in row 2 and column 3? (excluding headers and footers)': 'Jaka jest wartość komórki w 2 wierszu i 3 kolumnie? (wyłączając header i footer)',
    'How many rows are there in the table? (excluding headers and footers)': 'Ile wierszy zawiera tabela? (wyłączając header i footer)',

    'How many elements are there in the list?': 'Ile elementów jest w liście?',
    'What is the value in point 1 of the list?': 'Jaka jest wartość w 1 punkcie listy?',
    'What is the value in point 2 of the list?': 'Jaka jest wartość w 2 punkcie listy?',
    'What is the value in point 3 of the list?': 'Jaka jest wartość w 3 punkcie listy?',
    'What is the value in point 4 of the list?': 'Jaka jest wartość w 4 punkcie listy?',
    'What is the value in point 5 of the list?': 'Jaka jest wartość w 5 punkcie listy?',
}


/*
i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources : {
        en: {
            translations: {
                Introduction: "Introduction",
                "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop":
                  "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop",
                "Plugins to detect the user language":
                  "Plugins to detect the user language",
                "Plugins to load translations": "Plugins to load translations",
                "Optionally cache the translations":
                  "Optionally cache the translations",
                Advantages: "Advantages",
                "Flexibility to use other packages": "Flexibility to use other packages",
                'Score': 'Score'
            }
      },
      //pl: {
      //  translations: translationPl
      //},
    },
    ns: ["translations"],
    defaultNS: "translations"
  });*/

  i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    //lng: 'en',
    resources: {
      en: {
        translations: {
          /*"To get started, edit <1>src/App.js</1> and save to reload.":
            "To get started, edit <1>src/App.js</1> and save to reload.",
          "Welcome to React": "Welcome to React and react-i18next",
          welcome: "Hello <br/> <strong>World</strong>",*/
          'exp gained': '{{exp}} exp gained',

          'What is the value of variable `{var_name3}`?': 'What is the value of variable `{{var_name3}}`?',
          'What is the value of `{variable}` variable?': 'What is the value of `{{variable}}` variable?',
          'Expected output is `{correct_output}`': 'Expected output is `{{correct_output}}`',

          'Complete the code so that it prints "{word}" to the screen': 'Complete the code so that it prints "{{word}}" to the screen',
          'Write code that prints "{word}" to the screen': 'Write code that prints "{{word}}" to the screen',
          'Write code that prints {number} to the screen': 'Write code that prints `{{number}}` to the screen',
          'There is a small mistake in the given code. Correct it so that it prints {number}.' : 'There is a small mistake in the given code. Correct it so that it prints {{number}}.',
          'What are the values that the variable `{variable_name}` contains?': 'What are the values that the variable `{{variable_name}}` contains?',
          'Which operator should be chosen so that this code prints `{result}` to the screen?': 'Which operator should be chosen so that this code prints `{{result}}` to the screen?',

          'What are the attributes of the given class `{class_name}`?': 'What are the attributes of the given class `{{class_name}}`?',
          'What are the methods of the given class `{class_name}`?' : 'What are the methods of the given class `{{class_name}}`?',

        }
      },
      pl: {
        translations: {
            'Learn programming in 5 minutes a day': 'Naucz się programować w 5 minut dziennie',

            'Learn': 'Nauka',
            'Profile': 'Profil',
            'Logout': 'Wyloguj się',

            'Add more': 'Dodaj kolejny',

            'Choose your programming course': 'Wybierz kurs programowania',
            'Start': 'Rozpocznij',
            'Already started': 'Już rozpoczęty',

            'Thanks for your interest!': 'Dziękujemy za zainteresowanie!',
            'We are still working on the next courses! Follow us on social media to be notified about our updates!':
                'Jesteśmy w trakcie przygotowywania kolejnych kursów! Obserwuj nas na mediach społecznościowych i bądź na bieżąco z naszymi nowinkami!',
            'Cancel': 'Anuluj',

            'Languages': 'Kursy',

            'About': 'O nas',
            'Terms and Conditions': 'Regulamin',
            'Privacy Policy': 'Polityka prywatności',


            'Daily goal': 'Cel na dziś',
            'Your Score Today': 'Punkty dziś',
            'exp': 'pkt. dośw.',
            'Goal': 'Cel',
            'Last week': 'W ostatnim tygodniu',

            'Mon': 'Pon',
            'Tue': 'Wto',
            'Wed': 'Śro',
            'Thu': 'Czw',
            'Fri': 'Pt',
            'Sat': 'Sob',
            'Sun': 'Ndz',

            'Friends': 'Znajomi',
            'Invite your friends and learn together!': 'Zaproś swoich znajomych i uczcie się razem!',
            'Invite': 'Zaproś',
            'Invite your friend to Codilingo!': 'Zaproś swojego znajomego do Codilingo!',
            'Enter email': 'Email znajomego',
            'Type your message here (optional) ...': 'Wpisz wiadomość do znajomego (opcjonalnie) ...',

            'Join our FB groups': 'Dołącz do naszych grup na FB',
            'A group where you can have direct impact on our platform, propose features and suggest improvements':
                'Grupa, w której możesz mieć wpływ na rozwój naszej platformy zgłaszając błędy i proponując nowe funkcjonalności.',
            'A group where you can ask python-related questions and have them answered by experienced mentors':
                'Grupa, w której możesz zadawać pytania związane z pythonem i uzyskać odpowiedzi od doświadczonych mentorów',
            'A fun group where you can upload and browse funny images related to programming and IT':
                'Grupa na luzie, gdzie możesz wrzucać i przeglądać zabawne obrazki związane z programowaniem i IT.',


            'Follow Codilingo': 'Obserwuj Codilingo na',

            'beginning': 'początek',
            'print': 'instrukcja print',
            'numbers': 'liczby',
            'text operations': 'operacje tekstowe',
            'operators': 'operatory',
            'variables': 'zmienne',
            'booleans': 'typ logiczny',
            'loops': 'pętle',
            'conditions': 'warunki',
            'lists': 'listy',
            'dictionaries': 'słowniki',
            'tuples': 'krotki',
            'comprehension': 'wyrażenia listowe',
            'functions': 'funkcje',
            'classes': 'klasy',
            'modules': 'moduły',
            'imports': 'importy',
            'exceptions': 'wyjątki',
            'checkpoint 1': 'punkt kontrolny 1',
            'checkpoint 2': 'punkt kontrolny 2',
            'checkpoint 3': 'punkt kontrolny 3',
            'checkpoint 4': 'punkt kontrolny 4',

            'What does this code print to the screen?': 'Co ten kod wypisze na ekranie?',
            'There is a small mistake in the given code. Correct it.': 'W poniższym kodzie znajduje się mały błąd. Popraw go.',

            'There may be small mistake in the given code. Correct it.': 'W poniższym kodzie mógł się znaleźć mały błąd. Popraw go.',
            'Expected output is `{correct_output}`': 'Oczekiwany wynik: `{{correct_output}}`',

            'Complete the code so that it prints "{word}" to the screen': 'Dokończ poniższy kod tak, żeby wypisywał na ekran słowo "{{word}}"',
            'Write code that prints "{word}" to the screen': 'Napisz kod, który wypisze na ekran słowo "{{word}}"',
            'Write code that prints {number} to the screen': 'Napisz kod, który wypisze na ekran liczbę {{number}}',
            'There is a small mistake in the given code. Correct it so that it prints {number}.' :
                'W poniższym kodzie znajduje się mały błąd. Popraw go tak, żeby wypisać na ekranie liczbę {{number}}',
            'What is the value of variable `{var_name3}`?': 'Jaka jest wartość zmiennej `{{var_name3}}`?',
            'What is the value of `{variable}` variable?': 'Jaka jest wartość zmiennej `{{variable}}`?',
            'What are the values that the variable `{variable_name}` contains?': 'Jakie są wartości elementów zmiennej `{{variable_name}}`?',
            'Which operator should be chosen so that this code prints `{result}` to the screen?': 'Który operator należy wybrać, żeby wypisać `{{result}}` na ekran?',

            'What is the result of this expression?': 'Jaki jest wynik tego wyrażenia?',
            'What is the result of this operation?': 'Jaki jest wynik poniższej operacji?',

            'Lesson Completed!': 'Lekcja ukończona!',
            'Your progress on this lesson will be lost.': 'Utracisz swój dotychczasowy postęp w tej lekcji.',
            'Are you sure you want to end the lesson?': 'Czy na pewno chcesz zakończyć lekcję?',
            'Loading': 'Wczytuję',

            'CORRECT!': 'Prawidłowo!',
            "Correct! Keep it up!": 'Prawidłowo! Tak trzymaj!',
            "Great!": 'Świetnie!',
            "Perfect!": 'Perfekcyjnie!',
            "Good! Keep going!": "Dobrze! Tak trzymaj!",
            'This does not seem right...': 'Ta odpowiedź nie wydaje się poprawna...',
            "Oops. It's not correct...": 'Ups. To nie jest poprawna odpowiedź...',
            'SKIP': 'Pomiń',
            'CHECK': 'Sprawdź',
            'CONTINUE': 'Kontynuuj',
            'Continue': 'Kontynuuj',
            'Correct answer?': 'Prawidłowa odpowiedź?',

            'Awesome!': 'Wspaniale!',
            'Nice!': 'Nieźle!',
            'Cool!': 'Fajnie!',
            'Good': 'Dobrze',
            'Okay': 'W porządku',
            'exp gained': 'zdobywasz {{exp}} punktów doświadczenia',

            'Experience': 'Doświadczenie',
            'Time spent': 'Czas lekcji',
            'Accuracy': 'Dokładność',
            'Awesome': 'Wspaniale!',
            'Quick!': 'Błyskawicznie!',
            'Perfect': 'Idealnie',

            'About Codilingo': 'O Codilingo',
            'Our mission is to show you that programming is easy and fun.':
                'Naszym celem jest pokazać Ci, że programowanie jest proste i fajne.',
            'We want to prove you that everyone can understand code.':
                'Chcemy udowodnić Ci, że każdy może rozumieć i pisać kod.',
            'We try to avoid complex explanations, or even any explanations at all.':
                'Staramy się unikać skomplikowanych wyjaśnień. O ile to możliwe staramy się unikać wszelkich wyjaśnień.',
            'Using various methods that proved their effectiveness in natural language learning, we kindly introduce you to the world of computer programming.':
                'Przy użyciu różnych metod, których działanie było sprawdzone przy nauczaniu języków obcych, powoli wprowadzamy Cię w świat programowania.',
            'Taking baby steps with Codilingo, you will gradually get more and more familliar with the basic programming concepts.':
                'Dzięki Codilingo, drobnymi kroczkami, zaznajomisz się z podstawowymi zagadnieniami programowania.',

            'Courses': 'Kursy',
            'Language': 'Język',
            'Settings': 'Ustawienia',
            'Daily experience goal': 'Dzienny cel',
            'Set your daily goal': 'Ustaw swój dzienny cel',
            'Set your language': 'Ustaw język aplikacji',
            'Polish': 'Polski',
            'English': 'Angielski',
            'English (Great Britain)': 'Angielski (Wielka Brytania)',
            'English (USA)': 'Angielski (USA)',
            'French': 'Francuski',
            'Spanish': 'Hiszpanski',
            'German': 'Niemiecki',
            'update': 'zmień',
            'Minimum minimorum': 'Minimum minimorum',
            'Keeping in touch': 'Pozostanie w kontakcie',
            'Reasonable choice': 'Rozsądny wybór',
            'Engaged': 'Zaangażowany',
            'Insane': 'Szalony',
            'Save': 'Zapisz',
            'days': 'dni',


            'Using Codilingo on a mobile? For better user experience, switch to our': 'Używasz Codilingo na telefonie? Dla większego komfortu, skorzystaj z naszej',
            'mobile app': 'aplikacji mobilnej',


            ...lessonsHTML_PL,
            ...questionsHTML_PL,

        }
      },
      es: {
        translations: {
            'Profile': 'Perfil',
            'Logout': 'Cerrar sessión',
            'Cancel': 'Cancelar',
            'Settings': 'Configuración',

            'Choose your programming course': 'Añade tu curso de programación',
            'Start': 'Comienza',
            'Already started': 'Ya comenzado',

            'Daily goal': 'Objetivo Diario',
            'Your Score Today': 'Tu puntaje hoy',
            'Goal': 'Objetivo',
            'Last week': 'La semana pasada',
            'Mon': 'Lu',
            'Tue': 'Ma',
            'Wed': 'Mi',
            'Thu': 'Ju',
            'Fri': 'Vi',
            'Sat': 'Sa',
            'Sun': 'Do',


            'Friends': 'Amigos',
            'Invite your friends and learn together!': '¡Invita a tus amigos y aprende juntos!',
            'Invite': 'Invitar',
            'Invite your friend to Codilingo!': '¡Invita a tu amigos a Codilingo!',
            'Enter email': 'Ingrese el correo electrónico de tu amigo',
            'Type your message here (optional) ...': 'Escribe un mensaje aquí (opcional) ...',

            'Join our FB groups': 'Únete a nuestros grupos de FB',
            'Follow Codilingo': 'Siga Codilingo',

            'Courses': 'Cursos',
            'Learn': 'Aprender',

            'Daily experience goal': 'Objetivo de experiencia diaria',
            'update': 'actualizar',

            'Set your language': 'Configura tu idioma',
            'Language': 'Lengua',
            'Polish': 'Polaco',
            'English': 'Inglés',
            'English (Great Britain)': 'Inglés (Reino Unido)',
            'English (USA)': 'Inglés (USA)',
            'French': 'Francés',
            'Spanish': 'Español',
            'German': 'Alemán',

            'Insane': 'Loco',
            'day': 'dia',
            'days': 'dias',

            'SKIP': 'SALTAR',
            'CHECK': 'VERIFICAR',
            'Correct answer?': '¿Respuesta correcta?',
            'CORRECT!': 'CORRECTO!',
            'CONTINUE': 'CONTINUAR',
            'Are you sure you want to end the lesson?': '¿Estás seguro de que quieres terminar la lección?', //masculine vs feminine (seguro/segura) - check
            'Your progress on this lesson will be lost.': 'Su progreso en esta lección se perderá.',
        }
      }
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    //keySeparator: false, // we use content as keys

    nsSeparator: ':::',
    keySeparator: '::',

    interpolation: {
      escapeValue: false
    }
  });



export default i18n;