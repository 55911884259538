import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import SummaryField from './LessonCompletedSummaryField';



class LessonCompleted extends Component {
    /*
    TODO: replace with a more detailed lesson summary (time spent, exp + bonuses, comment)
    */

    //https://thumbs.dreamstime.com/b/smile-vector-icon-happy-smiling-face-emoticon-icon-flat-style-yellow-symbol-white-background-smile-vector-icon-happy-150159497.jpg
    //https://woolah.co/assets/image/emoji-POD/35.png
    //https://img.icons8.com/clouds/2x/happy.png

    render(){
        //let imageUrl = "https://woolah.co/assets/image/emoji-POD/35.png"
        let imageUrl = "/images/happy-face.png"
        const { t } = this.props

        return <div className="question-container container">
            <div className="vertical-center" style={{width: '100%'}}>
            <center>
            <img src={imageUrl} className="lessonCompletedMainImage" alt="lesson completed happy face"/>
            <h1 className="titleLogo">{t('Lesson Completed!')}</h1>
            <br/>
            <div className="lessonsSummary">
                <div className="row">
                    <div className="col-4">
                        <SummaryField type={'exp'} value={this.props.exp}/>
                    </div>
                    <div className='col-4'>
                        <SummaryField type={'time'} value={this.props.time}/>
                    </div>
                    <div className='col-4'>
                        <SummaryField type={'accuracy'} value={this.props.accuracy}/>
                    </div>
                </div>
            </div>
            <br/>
            <Link to='/learn'>
                <button
                    className='btn btn-secondary btn-lg btn-highlight btn-lesson bold'
                    onClick={() => this.props.dispatch({type: 'LOAD_DATA'})}
                    style={{'boxShadow': '0 1px 3px 0px rgba(0, 0, 0, .2)', width: '200px'}}>
                    {t('Continue')}
                </button>
            </Link>
           </center>
           </div>
        </div>

    }
}


function select (state) {
  return {
    data: state
  }
}


export default connect(select)(
    withTranslation()(
        LessonCompleted
    )
);
