import React, { Component } from 'react';
import {connect} from 'react-redux'

import LessonWidget from './LessonWidget';
//import history from '../../components/common/history'



/*function forwardTo (location) {
  history.push(location)
}*/


class LessonTree extends Component {

    render() {

        /*console.log(this.props.data.courseInfo)
        console.log(this.props.data.currentCourse)
        console.log(!this.props.data.currentCourse)
        console.log(!this.props.data.courseInfo)*/

        if(!this.props.data.currentCourse || !this.props.data.courseInfo){
            // TODO: not pleasant UX, should keep cached lesson tree in local storage
            return <div
                  className="lesson-tree-widget"
                  style={{'marginTop': '30px', 'textAlign': 'center'}}>

                  Loading...
            </div>
        }


        let language = this.props.data.currentCourse.toLowerCase()

        //console.log(language)

        if(! this.props.data.courseInfo[language]){
            return <div
                  className="lesson-tree-widget"
                  style={{'marginTop': '30px', 'textAlign': 'center'}}>

                  Loading...
            </div>
        }

        /*console.log(this.props.data.courseInfo)
        console.log(language)
        if (!this.props.data.courseInfo[language]){
           forwardTo('/courses')
           return <div>Redirecting to courses</div>
        }*/
        let lessonsLevels = this.props.data.courseInfo[language]['lessons']
        //TODO: reorder per section/unit
        console.log(lessonsLevels)
        return this.getLessonTree(lessonsLevels, language)

        //return this.getLessonTreeNew(this.props.data.courseInfo[language]['lessons'], language)
    }

    getLessonTree(lessonLevels, language) {
        const ChapterLabel = (chapterName, chapterNumber) => (
            <div className="row courseChapterLabel">
                <div className="unitNumberLabel">Chapter {chapterNumber + 1}</div>
                <div className="unitNameLabel">{chapterName}</div>
            </div>
        )
        const UnitLabel = (unitName, unitNumber) => (
            <div className="row courseUnitLabel">
                <div className="unitNumberLabel">Section {unitNumber + 1}</div>
                <div className="unitNameLabel">{unitName}</div>
            </div>
        )

        return (
            <div className="lesson-tree-widget" style={{ textAlign: 'center' }}>
                {ChapterLabel('Curiosity', 0)}
                {UnitLabel('Humble beginnings', 0)}

                {lessonLevels.map((lessonLevel, levelId) => (
                    <div className="row" key={levelId} style={{marginTop: '20px'}}>
                        {lessonLevel.map((lesson, key) => {
                            if (lesson.type === 'checkpoint') {
                                return (
                                    <div className="col-12" key={key}>
                                        <hr />
                                        <LessonWidget
                                            language={language}
                                            title={lesson.title}
                                            icon={lesson.icon}
                                            open={lesson.open}
                                            completed={lesson.completed}
                                            lessonsCompleted={lesson.lessonsCompleted}
                                            level={lesson.level}
                                            progress={Math.floor((lesson.lessonsCompleted / lesson.totalLessons) * 100)}
                                        />
                                        <hr />
                                    </div>
                                );
                            }

                            // Determine the column class based on the length of lessonLevel
                            const columnClass = { 1: 'col-12', 2: 'col-6', 3: 'col-4' }[lessonLevel.length] || 'col-12';

                            return (
                                <div className={columnClass} key={key}>
                                    <LessonWidget
                                        language={language}
                                        title={lesson.title}
                                        icon={lesson.icon}
                                        open={lesson.open}
                                        completed={lesson.completed}
                                        lessonsCompleted={lesson.lessonsCompleted}
                                        level={lesson.level}
                                        progress={Math.floor((lesson.lessonsCompleted / lesson.totalLessons) * 100)}
                                    />
                                    <br />
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        );
    }

    getLessonTreeNew(courseTree) {
        //TODO: może rozmieścić kafelki lekcji w koształcie sinusa o dłuższym okresie
        let language = 'Python'

        var lessons = [
            {title: 'lesson 1', icon: 'images/icons/learn.png'},
            {title: 'practice 1', icon: 'images/icons/exercise.png'},
            {title: 'lesson 2', icon: 'images/icons/learn.png'},
            {title: 'practice 2', icon: 'images/icons/exercise.png'},
            {title: 'lesson 3', icon: 'images/icons/learn.png'},
            {title: 'practice 3', icon: 'images/icons/exercise.png'}
            //TODO: dodać skarby (skrzynki) i specjalne typy lekcji
        ]


        const ChapterLabel = (chapterName, chapterNumber) => (
            <div className="row courseChapterLabel">
                <div className="unitNumberLabel">Chapter {chapterNumber + 1}</div>
                <div className="unitNameLabel">{chapterName}</div>
            </div>
        )
        const UnitLabel = (unitName, unitNumber) => (
            <div className="row courseUnitLabel">
                <div className="unitNumberLabel">Section {unitNumber + 1}</div>
                <div className="unitNameLabel">{unitName}</div>
            </div>
        )


        return (
            <div className="lesson-tree-widget" style={{ textAlign: 'center' }}>
                {courseTree.chapters.map((chapter, chapterNumber) => (
                    <div key={chapterNumber}>
                        {ChapterLabel(chapter.title, chapterNumber)}

                        {chapter.units.map((unit, unitNumber) => (
                            <div key={unitNumber}>
                                {UnitLabel(unit.title, unitNumber)}

                                {lessons.map((lesson, lessonNumber) => (
                                    <div className="row" key={lessonNumber} style={{marginTop: '20px'}}>
                                        <div className="col-12">
                                            <LessonWidget
                                                language={language}
                                                title={lesson.title}
                                                icon={lesson.icon}
                                                open={true} // todo: handle
                                                completed={false} // {lesson.completed}
                                                lessonsCompleted={0} // {lesson.lessonsCompleted}
                                                level={1} // {lesson.level}
                                                progress={0.0} // {Math.floor(lesson.lessonsCompleted / lesson.totalLessons * 100)}
                                            />
                                            <br />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}



function select (state) {
  return {
    data: state
  }
}

export default connect(select)(LessonTree);
