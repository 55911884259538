import React, { Component } from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import history from './history';
import { withTranslation } from 'react-i18next';

class BottomBar extends Component {

    constructor (props) {
        super(props)
        this.state = {showAlert: false}
    }

    render() {
        const { t } = this.props;

        let shouldDisplay = !window.location.href.includes('lesson') && !window.location.href.includes('auth');

        //console.log(this.props.location)
        if(!shouldDisplay)
            return <div/>

        return (
            <div className="bottom-bar">
                <div className="bottom-bar-buttons">
                    <Link to='/learn'><img src="/images/icons/learn4.png" alt="Play" className={window.location.href.includes('learn')? "bottom-bar-view-button-active": "bottom-bar-view-button-inactive"}/></Link>
                    { false ? <Link to='/debug/exercise'><img src="/images/icons/exercise5.png" alt="Exercise" className="bottom-bar-view-button-inactive"/></Link>: ''}
                    { false ? <Link to='/league'><img src="/images/icons/trophy.png" alt="Competition" className="bottom-bar-view-button-non-active"/></Link>: '' }


                    <Link to='/profile'><img src="/images/icons/profile2.png" alt="Profile" className={window.location.href.includes('profile')? "bottom-bar-view-button-active": "bottom-bar-view-button-inactive"}/></Link>
                    { false ? <Link to='/feed'><img src="/images/icons/notification3.png" alt="Feed" className="bottom-bar-view-button-non-active"/></Link>: ''}
                </div>
            </div>
        )
    }
}


// Which props do we want to inject, given the global state?
function select (state) {
  return {
    data: state
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
    connect(select)(
        withTranslation()(
            BottomBar
        )
    )
);
