import * as serviceWorker from './serviceWorker';

import 'babel-polyfill';

import React, {Component, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Route, Router, Switch, Redirect} from 'react-router-dom';
import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {Provider} from 'react-redux';
import {createLogger} from 'redux-logger';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";


import reducer from './reducers';
import rootSaga from './sagas';
//import {clearError} from './actions'

import history from './components/common/history';


import App from './components/App';
//import NotFound from './components/NotFound'

import {/*setAuthToken,*/ getAuthToken} from './auth/token';

import { put } from 'redux-saga/effects';

import Auth from './components/auth/Auth';
import Dashboard from './components/Dashboard';
import Lesson from './components/lesson/Lesson';
import ReactGA from 'react-ga';

import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development'){  // TODO: make sure !== vs != produce correct results
    ReactGA.initialize('UA-158933671-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}


const Profile = React.lazy(() => import('./components/profile/Profile'));

const About = React.lazy(() => import('./components/About'));
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy'));
const TermsAndConditions = React.lazy(() => import('./components/TermsAndConditions'));
const InitialTest = React.lazy(() => import('./components/what_to_learn/InitialTest'));

const CoursesDashboard = React.lazy(() => import('./views/CoursesDashboard'));
const JoinFromInvitation = React.lazy(() => import('./views/JoinFromInvitation'));

const LessonIntroduction = React.lazy(() => import('./components/lesson/LessonIntroduction'));
const LessonCompleted = React.lazy(() => import('./components/lesson/LessonCompleted'));



Sentry.init({dsn: "https://af3d1a5ace43447c8c5e9213b989b218@sentry.io/3766579"});


const logger = createLogger({
  // Ignore `FORBIDDEN_ACTION`
  predicate: (getState, action) => (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')? action.type !== 'FORBIDDEN_ACTION': false
})


const sagaMiddleware = createSagaMiddleware()

// Creates the Redux store using our reducer and the logger and saga middlewares
const store = createStore(reducer, applyMiddleware(logger, sagaMiddleware))

// We run the root saga automatically
sagaMiddleware.run(rootSaga)

/**
* Checks authentication status on route change
* @param  {object}   nextState The state we want to change into when we change routes
* @param  {function} replace Function provided by React Router to replace the location
*/
function isAuth () {
    /*console.log('isAuth')
    console.log(store.getState())*/
    const {isLoggedIn} = store.getState()
    //console.log(loggedIn)
    //store.dispatch(clearError())
    if (isLoggedIn){
        //console.log("LOGGED IN")
        return true;
    }
    return false;
}



// Mostly boilerplate, except for the routes. These are the pages you can go to,
// which are all wrapped in the App component, which contains the navigation etc
class AppFlow extends Component {

    /*componentDidMount(){
        console.log('-------------')
        console.log(getAuthToken())
        console.log('-------------')
    }*/
    componentDidMount(){
        //TODO: fetch special token from API, it should be set by the other part of the app and retrieved once here
        put({type: 'SYNCHRONIZE_TEST_STATE_REQUEST', testId: getAuthToken()})
    }

    render () {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <I18nextProvider i18n={i18n}>
                        <App>
                            <Suspense fallback={<div></div>}>
                                <Switch>
                                    <Route path='/auth' render={() => isAuth()?<Redirect to="/learn"/>: <Auth/>} />

                                    <ProtectedRoute path='/courses' component={CoursesDashboard} />

                                    <ProtectedRoute path='/learn' component={Dashboard} />
                                    <ProtectedRoute path='/learn/:language' render={<div/>} />
                                    <ProtectedRoute path='/lesson/:language/:lessonTitle/:level' component={Lesson}/>

                                    <ProtectedRoute path='/profile' component={Profile}/>
                                    <ProtectedRoute path='/profile/:userSlug' component={Profile}/>

                                    <Route path='/terms-and-conditions' render={()=> <TermsAndConditions/>}/>
                                    <Route path='/privacy-policy' render={()=> <PrivacyPolicy/>}/>
                                    <Route path='/about' render={()=> <About/>}/>
                                    <Route path='/initial-test' render={()=><InitialTest/>}/>
                                    <Route path='/join/:invitationHash' render={() => isAuth()?<Redirect to="/learn"/>: <Auth/>}/>


                                    <Route path='/debug/courses' render={() => <CoursesDashboard/>} />
                                    <Route path='/debug/lesson/:language/:lessonTitle/:level' render={() => <Lesson/>}/>
                                    <Route path='/debug/lesson/introduction' render={() => <LessonIntroduction lessonTitle={'Numbers'} lessonIntroductionContent={'Hello, this is code: <br> `print("hello world!")`'}/>} />
                                    <Route path='/debug/lesson/completed' render={()=><LessonCompleted exp={15} time={112.5} accuracy={92}/>} />


                                    <Route path='*' render={() => isAuth()?<Redirect to="/learn"/>: <Redirect to="/auth"/>} />
                                </Switch>
                            </Suspense>
                        </App>
                    </I18nextProvider>
                </Router>
            </Provider>
        )
    }
}


class ProtectedRoute extends Component {
  render() {
    const { component: Component, ...props } = this.props

    return (
      <Route
        {...props}
        render={props => (
          isAuth() ?
            <Component {...props} /> :
            <Redirect to='/auth' />
        )}
      />
    )
  }
}



ReactDOM.render(<AppFlow />, document.getElementById('root'))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();