import React, { Component } from 'react';
import { connect } from 'react-redux';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Question from './Question';
import QuestionControls from './QuestionControls';
import LessonIntroduction from './LessonIntroduction';
import LessonCompleted from './LessonCompleted';
import QuestionHint from './QuestionHint';

import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';



const MySwal = withReactContent(Swal);


const MAX_EXP = 15;


class Lesson extends Component {
    /**
    this component holds the whole lesson flow
        - loading lesson data (introduction, questions)
        - going through all the questions
        - showing lesson summary
    **/

    constructor(props){
        super(props)
        this.props.dispatch({
            type: 'SET_GENERIC',
            questionNumber: 0,
            question: this.props.data.lesson.questions[0],
            maxQuestions: this.props.data.lesson.questions.length
        })
        /*this.state = {
            view: 'introduction'
        }*/ // introduction -> lesson -> completed
        this.loadQuestions = this.loadQuestions.bind(this)
    }

    loadQuestions(language, title, level){
        this.props.dispatch({
            type: 'LOAD_LESSON',
            course: language,
            title: title,
            level: level,
        })
    }

    componentDidMount(){
        let normalizedLessonTitle = this.props.match.params.lessonTitle
        this.loadQuestions(this.props.match.params.language, normalizedLessonTitle, this.props.match.params.level - 1)
    }

    render() {

        const { t } = this.props;
        //TODO: should this be a separate question or just different component for the code?
        const questionComponent = /*this.props.match.params.language === 'html_css'?
           <HTMLQuestion question={this.props.data.question || this.props.data.lesson.questions[0]}/>
           :*/
           <Question question={this.props.data.question || this.props.data.lesson.questions[0]}/>


        if(!this.props.data.lesson.questions || !this.props.data.question){
            return <div>{t('Loading')}...</div>
        }
        else if(this.props.data.lessonView === 'introduction'){
            // TODO: should lesson questions be loaded before the introduction or after?
            return <LessonIntroduction
                lessonTitle={this.props.data.lesson.name}
                lessonIntroductionContent={this.props.data.lesson.introduction}
                onClick={
                    () => this.props.dispatch({
                        'type': 'SET_GENERIC',
                        lessonView: 'lesson',
                        lessonStartTime: Date.now()
                    })
                }
            />
        }
        else if(this.props.data.lessonView === 'hint'){
            return <QuestionHint
                lessonTitle={this.props.data.lesson.name}
                lessonIntroductionContent={this.props.data.lesson.introduction}
                onClick={
                    () => this.props.dispatch({
                        'type': 'SET_GENERIC',
                        lessonView: 'lesson',
                    })
                }
            />
        }
        else if(this.props.data.lessonView === 'lesson'){
            return <div>
                <div className="question-container" style={{overflowY: 'auto', overflowX: 'hidden'}}>
                    <div className="row progressbar-row">
                        <div className="exitButton">
                            <img src="/images/x-arrow-4.png"
                                alt="exit-button"
                                className="exit-button"
                                onClick={()=>confirmExit(this.props)}/>
                        </div>
                        <ProgressBar progress={Math.floor(100 * this.props.data.questionNumber/this.props.data.maxQuestions)}/>
                        <div className="lessonHearts">
                            <img src="/images/icons/heart.png" alt="exit-button" className="heartsIcon"/> 5
                        </div>
                    </div>
                    {questionComponent}
                </div>
                <div className='emptySpaceQuestion'/>
                <QuestionControls loadNextQuestion={
                        () => {
                            if(this.props.data.questionNumber + 1 >= this.props.data.maxQuestions){
                                this.props.dispatch({
                                    'type': 'SET_GENERIC',
                                    lessonView: 'completed'
                                })

                                this.props.dispatch({
                                    'type': 'LESSON_COMPLETED',
                                    course: this.props.match.params.language,
                                    lesson: this.props.data.lesson.name,
                                    level: this.props.data.lesson.level + 1,
                                    questionLog: this.props.data.questionLog
                                })

                                return
                            }
                            this.props.dispatch({
                                'type': 'SET_GENERIC',
                                questionNumber: this.props.data.questionNumber + 1,
                                question: this.props.data.lesson.questions[this.props.data.questionNumber + 1]
                            })
                        }
                    }
                    question={this.props.data.question || this.props.data.lesson.questions[0]}
                    />
            </div>
        }
        else if (this.props.data.lessonView === 'completed'){
            console.log('this.props')
            console.log(this.props)
            console.log('state')
            console.log(this.state)

            return <LessonCompleted
                exp={calculateExpGained(this.props.data.questionLog)}
                time={Math.floor((Date.now() - this.props.data.lessonStartTime)/1000)}
                accuracy={calculateAccuracy(this.props.data.questionLog)}
            />
        }

        return <div>{t('Loading')}....</div>
    }
}


function calculateExpGained(answers){

    let wrongAnswersNum = answers.filter(answer => answer.is_correct === false? 1: 0).length;
    let expPenalty = wrongAnswersNum < 3? 0: (wrongAnswersNum - 2);
    let exp = MAX_EXP - expPenalty;

    return Math.max(10, exp)
}


function calculateAccuracy(answers){
    // percentage accuracy
    let allAnswersNum = answers.length;
    let wrongAnswersNum = answers.filter(answer => answer.is_correct === false? 1: 0).length;

    return 100 * (allAnswersNum - wrongAnswersNum)/allAnswersNum;
}


function confirmExit(props){
    const { t } = props
    let redirectLink = "/learn"
    MySwal.fire({
      title: <p>{t('Are you sure you want to end the lesson?')}</p>,
      text: t('Your progress on this lesson will be lost.'),
      showCancelButton: true,
      confirmButtonText: <span>OK</span>,
      cancelButtonText: <span>{t('Cancel')}</span>,
    }).then(result => {
        if(result.value){
            //window.location.replace(redirectLink)
            /*props.history.push({
             pathname:redirectLink,
             state: props.data
            });*/
            props.history.push(redirectLink)
            //return <Redirect to={redirectLink}/>
        }
    })
}


class ProgressBar extends Component {

    render(){

        return <div className="progressbar-wrapper">
                <div className="progress-bar progress-bar-striped progress-bar-animated"
                style={{
                    "width": this.props.progress + '%',
                    'borderTopLeftRadius': '10px',
                    'borderBottomLeftRadius': '10px',
                    'fontSize': '8px',
                }}>
                &nbsp;
                </div>
        </div>
    }
}


function select (state) {
  return {
    data: state
  }
}

export default withRouter(
    connect(select)(
        withTranslation()(
            Lesson
        )
    )
);