 // This file contains the sagas used for async actions in our app. It's divided into
// "effects" that the sagas call (`authorize` and `logout`) and the actual sagas themselves,
// which listen for actions.

// Sagas help us gather all our side effects (network requests in this case) in one place

import history from '../components/common/history';
import { take, call, put, fork, select } from 'redux-saga/effects';
import { api } from '../api';
//import {setCookie} from '../common/simpleCookieUtils';
//import consoleDebug from './../common/debugLogging';
import {setAuthToken, removeAuthToken} from '../auth/token';

import {
  //SENDING_REQUEST,
  //REQUEST_ERROR,

  REGISTER_OR_LOGIN,
  //SWITCH_COURSE
} from '../actions/constants';



export function * registerOrLogin(){
    while(true){
        const request = yield take(REGISTER_OR_LOGIN)
        //console.log('REGISTER OR LOGIN')

        var d = new Date();
        var timezoneOffset = d.getTimezoneOffset();

        let data = {
            timezoneOffset: timezoneOffset,
            ...request.data
        }

        try {
            const response = yield call(
                [api, 'registerExternal'],
                data
            )

            const output = yield call([response, 'json'])

            yield put({
                type: 'SET_GENERIC',
                isRegistering: true,
            })

            setAuthToken(output.access_token)

            yield put({
                type: 'LOAD_DATA',
            })
            /*yield put({
                type: 'SET_GENERIC',
                    userProfile: {
                        'nick': output.username,
                        'slug': output.slug,
                        'avatar': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMEuWxEdT234XVE74W5H1ieglcXsegSzUV-ZCqZVftGEZpDknR&s',
                        //'interests': [],
                        //'achievements': [],
                        //'streakDays': 0,
                        //'language': 'en',
                        //'timeZone': '',
                        //'bits': 0,
                    }
            })*/
        } catch (error){
            yield put({
                type: 'SET_GENERIC',
                error: error,
                isRegistering: false
            })
        } finally {
            yield put({
                type: 'SET_GENERIC',
                isLoggedIn: true,
                isRegistering: false,
            })
            yield call (forwardTo, '/learn')
        }
    }
}


export function * loadData(){
    while(true){
        const request = yield take('LOAD_DATA')
        //console.log('loadData: LOAD DATA')

        try{
            //console.log('loadData: CALLING GETDATA')
            const response = yield call(
                [api, 'getData']
            )

            console.log('loadData: getting output')
            if(!response.ok){
                //INVALID TOKEN
                yield put({
                    type: 'SET_GENERIC',
                    error: 'invalid token',
                    isLoggedIn: false,
                })

                removeAuthToken()
                forwardTo('/auth')
                //TODO: doesnt work, needs
                continue;
            }

            let output = yield call([response, 'json'])
            //console.log(output)
            if(Object.keys(output.courseInfo).length === 0){
                console.log('LOAD DATA COURSE INFO missing')
                console.log(output.courseInfo)
                yield call(forwardTo, '/courses')
            }

            let previousCurrentCourse = yield select((state) => state.currentCourse);

            function getCurrentCourse(){
                if(request.currentCourse){
                    return request.currentCourse;
                } else if (previousCurrentCourse) {
                    return previousCurrentCourse;
                } else if (Object.keys(output.courseInfo).includes(output.currentCourse)) {
                    // maybe not needed
                    return output.currentCourse;
                } else {
                    // maybe not needed
                    return Object.keys(output.courseInfo)[0];
                }
            }

            let currentCourse = getCurrentCourse();

            yield put({
                type: 'SET_GENERIC',
                userProfile: output.userProfile,
                courseInfo: output.courseInfo,
                currentCourse: currentCourse,
            })

        } catch (error){
           console.log('loadData: loadData error')
           yield put({
                type: 'SET_GENERIC',
                error: error,
                isLoggedIn: false,
           })

            //removeAuthToken()
            yield call(removeAuthToken) //(??)

        } finally {
            //console.log("loadData: finally")
        }
    }
}


export function * lessonCompleted(){
    while(true){
        const request = yield take('LESSON_COMPLETED')
        /* REQUEST data
        {
            course: 'python',
            lesson: this.props.data.lesson,
            level: this.props.data.lessonLevel
        }*/
        try{
            let response = yield call(
                [api, 'updateLessonProgress'],
                {
                    'course': request.course,
                    'title': request.lesson,
                    'level': request.level,
                    'answers': request.questionLog,
                }
            )

            //let output =
            yield call([response, 'json'])

            //console.log('CALL loadData from lessonCompleted')
            //yield put({type: 'LOAD_DATA'})
        } catch (error){
            yield put({
                type: 'SET_GENERIC',
                error: error,
            })
        } finally {

        }
    }

}


export function * loadLesson(){
    while(true){
        const request = yield take('LOAD_LESSON')
        /* REQUEST data
        {
            course: 'python',
            lesson: this.props.data.lesson,
            level: this.props.data.lessonLevel
        }*/
        try{
            let response = yield call(
                [api, 'getQuestions'],
                {
                    'course': request.course,
                    'title': request.title,
                    'level': request.level,
                }
            )

            if(!response.ok){
                //INVALID TOKEN
                yield put({
                    type: 'SET_GENERIC',
                    error: 'invalid token',
                    isLoggedIn: false,
                })

                removeAuthToken()
                forwardTo('/auth')
                continue;
            }

            let output = yield call([response, 'json'])

            //console.log('CALL getQuestions from loadLesson')
            let processedQuestions = output.questions.map(
                q => {
                    return {...q.content, id: q.id}
                }
            )

            //console.log(processedQuestions)
            let view = (output.introduction && output.level === 1)? 'introduction': 'lesson';

            yield put({
                type: 'SET_GENERIC',
                lesson: {
                    name: request.title,
                    level: request.level,
                    introduction: output.introduction,
                    questions: processedQuestions
                },
                question: processedQuestions[0],
                maxQuestions: processedQuestions.length,
                questionLog: [],
                lessonView: view,
                lessonStartTime: view === 'lesson'? Date.now(): undefined, // start counting time
            })

        } catch (error){
            yield put({
                type: 'SET_GENERIC',
                error: error,
            })
        } finally {

        }
    }

}


export function * saveGoogleAnalyticsEvent(){
    while(true){
        const request = yield take('SAVE_GA_EVENT')
        /* REQUEST data
        {
            course: 'python',
            lesson: this.props.data.lesson,
            level: this.props.data.lessonLevel
        }*/
        let response = yield call(
            [api, 'getQuestions'],
            {
                'course': request.course,
                'title': request.title,
                'level': request.level,
            }
        )

        if(!response.ok){
            //INVALID TOKEN
            yield put({
                type: 'SET_GENERIC',
                error: 'invalid token',
                isLoggedIn: false,
            })

            removeAuthToken()
            forwardTo('/auth')
            continue;
        }

    }

}


export function * addCourse(){
    while(true){
        const request = yield take('ADD_COURSE')

         try{
            let response = yield call(
                [api, 'addCourse'],
                {
                    'course': request.course,
                }
            )

            yield put({type: 'LOAD_DATA'})

        } catch (error){
            yield put({
                type: 'SET_GENERIC',
                error: error,
            })
            continue;
        } finally {
            yield call (forwardTo, '/learn')
        }

    }
}


// The root saga is what we actually send to Redux's middleware. In here we fork
// each saga so that they are all "active" and listening.
// Sagas are fired once at the start of an app and can be thought of as processes running
// in the background, watching actions dispatched to the store.
export default function * root () {
  yield fork(loadLesson)
  yield fork(registerOrLogin)
  yield fork(loadData)
  yield fork(lessonCompleted)
  yield fork(addCourse)
  /*yield all([
    fork(loadLesson),
    fork(registerOrLogin),
    fork(loadData),
    fork(lessonCompleted)
  ])*/
}


// Little helper function to abstract going to different pages in the scope of the application
function forwardTo (location) {
  history.push(location)
}


// Go to external link
/*function redirectTo (location) {
  window.location.replace(location)
}*/