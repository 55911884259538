import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { api } from '../../api'


import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);


//Followers?
class Friends extends Component {
    render() {
        const { t } = this.props;

        this.props.data.friends = [
            //{slug: 'jan', avatar: 'https://www.gravatar.com/avatar/604d8c4bb4ac807452e28968672d?d=robohash&s=200', nick: 'Jan', exp: 200},
            //{slug: 'zosia', avatar: 'https://www.gravatar.com/avatar/604d8c4bb4a07452e28968672d?d=robohash&s=200', nick: 'Zosia', exp: 1200},
            //{slug: 'slawek', avatar: 'https://www.gravatar.com/avatar/604d4bb4ac807452e28968672d?d=robohash&s=200', nick: 'Sławek', exp: 3200},
            {slug: 'gosia', avatar: 'https://www.gravatar.com/avatar/604d8c4bb4ac807452e289672d?d=robohash&s=200', nick: 'Gosia', exp: 500},
            {slug: 'pawel', avatar: 'https://www.gravatar.com/avatar/604d8c4bb4ac8074e28968672d?d=robohash&s=200', nick: 'Paweł', exp: 700},
        ]

        this.props.data.friends = []

        let inviteUrl = 'https://app.codilingo.com/'; //'https://invite.codilingo.com/DJAPOWJDLKA'
        //zamiast Find zrobić kolejny wiersz "friend" z ikonką add (+)

        return <div className="widgetProfile">
            <center><h2 className="widgetTitle">{t('Friends')}</h2></center>

            <div style={{'maxHeight': '200px', 'minHeight': '50px', 'overflowY': 'auto'}}>
                {
                    this.props.data.friends.map( (friend, key) => (
                        <div style={{"border": "1px solid lightgray"}} key={key}>
                            <Link to={"/profile/" + friend.slug}>
                                <img
                                    src={friend.avatar}
                                    style={{'width': '50px', height: '50px', 'borderRadius': '25px'}}
                                    alt={friend.slug}/>
                            </Link>
                            &nbsp; <c style={{'fontSize': '14px'}}><b>{friend.nick}</b> &nbsp; {friend.exp}exp</c>
                        </div>)
                    )
                }
                {
                    this.props.data.friends.length === 0?
                        <div style={{color: 'gray'}}>
                            {false&&t('Currently you have no friends in Codilingo.')}<br/>
                            <center>{t('Invite your friends and learn together!')}</center><br/>
                        </div>
                        : ''
                }
            </div>
            <center>
                <button className="btn btn-primary btn-lg btn-normal" onClick={() => showInvitePopup(t, inviteUrl)}>{t('Invite')}</button>&nbsp;
                {false&&<button className="btn btn-primary btn-lg btn-normal">Find</button>}
            </center>
            <br/>
        </div>
    }
}

let appId = 2541275996154906;
//let caption = "I'm learning programming with Codilingo. Join me! Let's learn together!";
let caption = "Join me on Codilingo! Let's learn together!"
let hashtags = "codilingo,programming"

// images:
// https://www.freepik.com/premium-vector/illustration-email-marketing-message-concept_8748258.htm#query=send%20email&position=42&from_view=keyword
//https://img.freepik.com/free-vector/mail-write-get-send-icons-vector-illustration-flat-style_1284-42663.jpg?w=826&t=st=1666438939~exp=1666439539~hmac=26f26e1564a0d29af87e6cf57f221d3fcbb995d850240740724a83de6638c18a
//https://experihub.com/wp-content/uploads/2018/09/betetr.jpg
//https://skinlyaesthetics.com/wp-content/uploads/2020/05/bring-a-friend.jpg
// https://img.freepik.com/free-vector/preparing-test-together-learning-studying-with-friends-effective-revision-revision-timetables-planning-how-revise-exams-concept-pinkish-coral-bluevector-isolated-illustration_335657-1258.jpg
//ladne
function showInvitePopup(t, inviteUrl){
    //const {t, i18n} = props
    //let t = (t) => t;
    MySwal.fire({
      title: <p>{t('Invite your friends to Codilingo!')}</p>,
      //text: t('Email, message'),
      html: `
      <div style="text-align: justify">
        <img src='https://img.freepik.com/free-vector/preparing-test-together-learning-studying-with-friends-effective-revision-revision-timetables-planning-how-revise-exams-concept-pinkish-coral-bluevector-isolated-illustration_335657-1258.jpg?w=1380&t=st=1666453062~exp=1666453662~hmac=0dd92eb003032dd51d407096a900303276255cad2889cf38ded76fa736c38e1b'/>

        ${t("It's much funnier to learn together so invite your friends and get the best learning experience!")} :)<br/><br/><br/>


        <div class="input-group"
          style="box-sizing: border-box; transition: border-color .3s,box-shadow .3s; border: 1px solid #d9d9d9; border-radius: .1875em; background: inherit; box-shadow: inset 0 1px 1px rgba(0,0,0,.06);color:inherit;font-size: 1.125em;">
          <input type="text" readonly class="form-control" style="font-size: 18px;padding: 1.5em 0.5em 1.5em 0.5em;" value="${inviteUrl}">
          <div class="input-group-append">
            <button class="btn btn-primary btn-normal" id="inviteLink" type="button" aria-haspopup="true" aria-expanded="false" style="color:blue;" onclick="let inviteLink = document.getElementById('inviteLink'); console.log(inviteLink); inviteLink.select(); inviteLink.setSelectionRange(0, 99999);  navigator.clipboard.writeText(inviteLink.value); alert('Copied the text: ' + inviteLink.value);"><b>${t('Copy link')}</b></button>
          </div>
        </div><br/><br/>

        Or share on...<br/><br/></div>
        <div style="justify-text: center">
          <a href="https://www.facebook.com/dialog/feed?app_id=${appId}&link=${inviteUrl}" target="blank"><button class="btn btn-primary btn-lg btn-normal" style="width:45%; color: navy">Facebook</button></a>
          <a href="https://twitter.com/share?text=${caption}&url=${inviteUrl}&hashtags=${hashtags}" target="blank"><button class="btn btn-primary btn-lg btn-normal" style="width:45%; color: blue">Twitter</button></a>
        </div>
      </div>`,
      //https://stackoverflow.com/questions/6208363/sharing-a-url-with-a-query-string-on-twitter
      //https://www.facebook.com/dialog/feed?app_id=${appId}&display=popup&caption=${caption}&link=${inviteUrl}&redirect_uri=${inviteUrl}
      //https://www.facebook.com/dialog/feed?app_id=2541275996154906&display=popup&caption=An%20example%20caption&link=https://localhost:3000&redirect_uri=https://app.codilingo.com
      //https://www.facebook.com/sharer/sharer.php?u=${inviteUrl}&quote=You+are+amazing
        // https://www.facebook.com/dialog/feed?
      /*input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },*/
      showLoaderOnConfirm: true,
      showCloseButton: true,
      showConfirmButton: false,

      /*showCancelButton: true,
      confirmButtonText: <span>OK</span>,
      cancelButtonText: <span>{t('Cancel')}</span>,*/

      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!'
        }
      },

      preConfirm: (login) => {
        /*return fetch(`https://api.github.com/users/${login}`)
           .then(response => {
              if (!response.ok) {
                throw new Error(response.statusText)
              }
              return response.json()
           })
           .catch(error => {
                MySwal.showValidationMessage(
                  `Request failed: ${error}`
                )
           })*/

          let email = document.getElementById("swal-input-email").value
          let message = document.getElementById("swal-input-message").value

          let email_regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
            //  [a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?
            // enough?
            // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript

          if( !email_regex.test(email) ){
            return MySwal.showValidationMessage(
              `Please provide a correct email. ${email} is not a valid email.`
            )
          }

        return api.inviteFriend(email, message)
          .then(response => {
            if(response.status===405){
               throw new Error('Cannot invite ' + email + ', already registered.')
            }
            if(response.status===406){
               throw new Error('Invitation limit exceeded.')
            }
            if(response.status===407){
               throw new Error('Cannot invite ' + email)
            }
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            MySwal.showValidationMessage(
              //`Request failed: ${error}`
              error.message
            )
          })
      },
      allowOutsideClick: () => !MySwal.isLoading()
    }).then(
        (result) => {
          if (result.value !== undefined) {
            /*MySwal.fire({
              title: `${result.value.login}'s avatar`,
              imageUrl: result.value.avatar_url
            })*/
            MySwal.fire({
              type: 'success',
              text: t('Thank you for inviting your friend!'),
            })


          }
        }
    )
}


function select (state) {
  return {
    data: state
  }
}

export default connect(select)(
    withTranslation()(
        Friends
    )
);