/*
 * These are the variables that determine what our central data store (`../reducers/index.js`)
 * changes in our state.
 */

export const UPDATE_AUTHORIZATION = 'UPDATE_AUTHORIZATION'
export const SENDING_REQUEST = 'SENDING_REQUEST'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGOUT = 'LOGOUT'
export const REQUEST_ERROR = 'REQUEST_ERROR'
export const REMOVE_ERROR = 'REMOVE_ERROR'

export const REGISTER_OR_LOGIN = 'REGISTER_OR_LOGIN'


export const SWITCH_COURSE = 'SWITCH_COURSE'